import React, { useEffect,useState } from 'react';
import HeaderI from '../../front/NavbarI';
import FooterI from '../../front/FooterI';
import AvisoPrivacidadView from './avisoPrivacidadView';
import {motion} from "framer-motion";
export default function AvisoPrivacidad() {

  useEffect(() => {
    document.querySelector('body').scrollTo(0,0)
  }, []);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  

  return (
    <motion.div
    initial={{  opacity:0 }}
      animate={{opacity:1 }}
      exit={{opacity:0}}
      transition={{ ease: "easeInOut", duration: 0.6 }}>
      <HeaderI />

        <AvisoPrivacidadView />

      <FooterI />
    </motion.div>
  );
}
