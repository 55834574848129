import React, {useState} from 'react'
import '../../../css/style.css'
import logo from '../../../img/logo_blanco.png';
import logo2 from '../../../img/logo.png';
import icono_user from '../../../img/icnos-user-blanco.png';
import icono_whats from '../../../img/icnos-whatsblanco.png';
import icon_home from '../../../img/home-icon.png';
import foto_perfil from '../../../img/foto-perfil.png';
import HeaderDashboar from './components/header.comoponent';
import NavDashboard from './components/nav.component'
import Inicio from './components/inicio.component'
import Credito from './components/credito.component';
import EstadoCuenta from './components/estadoCuenta.component';
import Datos from './components/datos.component';


export default function Dashboard() {
    const[step,setStep]= useState(0)
    return (
        <div>
            <HeaderDashboar />
            <div className="">
                <div className="row">
                    {/* <NavDashboard />
                    {step === 0 ? <Inicio /> : null}
                    {step === 1 ? <Credito /> : null}
                    {step === 2 ? <EstadoCuenta /> : null}
                    {step === 3 ? <Datos /> : null} */}
                </div>
            </div>
        </div>
    )
}
