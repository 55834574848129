import React from 'react'
import estado_cuenta from '../../../../img/estado_cuenta.png';
import {motion} from "framer-motion";

export default function EstadoCuenta() {
    
    return (
        <motion.div
      initial={{  opacity:0 }}
        animate={{opacity:1 }}
        exit={{opacity:0}}
        transition={{ ease: "easeInOut", duration: 0.6 }}
        className="col-md-3 col-lg-10 d-md-block grey-background p-0 m-0">
                        <div className="container-fluid top-50">
                            <div className="mt-5 mb-5 text-center ">
                                <img src={estado_cuenta} alt="PRADO'S" className="img-fluid mb-4" width="80" />
                                <h1 className="txt-blue-lig">Aquí puedes descargar</h1>
                                <h1 className="text-strong-blue">Tu estado de cuenta actual</h1>
                                <div className="d-flex justify-content-center mt-5 mb-4">
                                    <a href='/solicitud/personal/6' className="btn btn-shadow-2">
                                        Estado de cuenta
                                    </a>
                                </div>
                                <div className="mt-5">
                                    <h5><strong>¿Necesitas un estado de cuenta anterior?</strong></h5>
                                    <h6>Comunícate con nosotros</h6>
                                    <div className="row mt-5">
                                        <div className="col text-end">
                                            <button className="btn btn-sm btn-border-dark">
                                                <a href="tel:+523336168059" className="a_none_style text-blue" target="_blank" rel="noopener noreferrer">33 3616-8059</a>
                                            </button>
                                        </div>
                                        <div className="col text-start">
                                            <button className="btn btn-sm btn-border-dark">
                                                <a href="tel:+523315206918" className="a_none_style text-blue" target="_blank" rel="noopener noreferrer">331 520-6918</a>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
    )
}