import "./popup.css";
import {
  Link,
  useHistory,
  useParams,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import Popup from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import React, { useEffect, useState } from "react";
import ApiClient from "../../../services/apiclient";
import AlertMessage from "../../alert.component";
import { TramiteCreditoContext } from "../../../context/TramiteCreditoProvider";
import logo_balnco from "../../../img/logo_blanco.png";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function PopUpContrato({
  isPopupVisible,
  setPopupVisibility,
  setNextNav,
  setShowAlert
}) {
  const history = useHistory(); 
  const apiClient = ApiClient.getInstance();
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const { credito, updateCredito, deleteCredito } = React.useContext(
    TramiteCreditoContext
  );
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [isLoading, setIsLoading] = useState(false);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  const contratoRes = { accepted: false };
  const [loading, setLoading] = useState(false);
  const [aceptarAnexos, setaceptarAnexos] = useState(false);
  const [error, setError] = useState(false)

  let contrato;
  const [tipoContratro, setTipoContrato] = useState(null);

  const [contractInfo, setContractInfo] = useState([]);

  useEffect(() => {
    creditTypeP();
  }, []);
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const infoContrato = async () => {
    const { creditType } = await apiClient.getActiveCredit();
    if (creditType === "pabs") {
      apiClient
        .getContractPabs()
        .then((data) => {
          setContractInfo(data);
          console.log(contractInfo);
        })
        .catch((error) => {
          setMessage("Esperando aprobación para cargar Tu Contrato.");
          setSeverity("error");
          setOpenMessage(true);
        });
    } else if (creditType === "nomina") {
      apiClient
        .getContractNomina()
        .then((data) => {
          setContractInfo(data);
          console.log(contractInfo);
        })
        .catch((error) => {
          setMessage("Esperando aprobación para cargar Tu Contrato.");
          setSeverity("error");
          setOpenMessage(true);
        });
    } else if (creditType === "personal") {
      apiClient
        .getContractPersonal()
        .then((data) => {
          setContractInfo(data);
          console.log(contractInfo);
        })
        .catch((error) => {
          setMessage("Esperando aprobación para cargar Tu Contrato.");
          setSeverity("error");
          setOpenMessage(true);
        });
    }
  };
  const creditTypeP = async () => {
    const { creditType } = await apiClient.getActiveCredit();
    console.log("PopUp: ", creditType);
    if (creditType === "nomina") {
      contrato = setTipoContrato("nomina");
      infoContrato();
    }

    if (creditType === "personal") {
      contrato = setTipoContrato("personal");
      infoContrato();
    }

    if (creditType === "pabs") {
      contrato = setTipoContrato("pabs");
      infoContrato();
    }
  };

  const aceptar = async () => {
    if (aceptarAnexos == true) {
      setLoading(true);
      setIsLoading(true);
      const { creditType } = await apiClient.getActiveCredit();

      contratoRes.accepted = true;

      if (creditType === "nomina") {
        apiClient
          .contratoNomina(contratoRes)
          .then((r) => {
            history.push("/dashboard");
            setPopupVisibility(false);
            // setShowAlert(true)
          })
          .catch((err) => {
            setLoading(false);
            setMessage("Ha ocurido un error al aceptar el contrato.");
            setSeverity("error");
            setOpenMessage(true);
          });
      }

      if (creditType === "personal") {
        apiClient
          .contratoPersonal(contratoRes)
          .then((r) => {
            history.push("/dashboard");
            setNextNav(0);

            setPopupVisibility(false);
            // setShowAlert(true)
          })
          .catch((err) => {
            setNextNav(0);
            history.push("/dashboard");
            setPopupVisibility(false);
            setLoading(false);
            setIsLoading(false);
            // setShowAlert(true)
            // setMessage("Ha ocurido un error al aceptar el contrato.");
            //setSeverity("error");
            //setOpenMessage(true);
          });
      }

      if (creditType === "pabs") {
        apiClient
          .contratoPABS(contratoRes)
          .then((r) => {
            //history.push("/dashboard2");
            setNextNav(0);
            setPopupVisibility(false);
            setShowAlert(true)
          })
          .catch((err) => {
            setLoading(false);
            setIsLoading(false);
            setMessage("Ha ocurido un error al aceptar el contrato.");
            setSeverity("error");
            setOpenMessage(true);
          });
      }

      if (creditType === "diremovil") {
        apiClient
          .contratoDiremovil(contratoRes)
          .then((r) => {
            history.push("/dashboard");
            setNextNav(0);

            setPopupVisibility(false);
            // setShowAlert(true)
          })
          .catch((err) => {
            setNextNav(0);
            history.push("/dashboard");
            setPopupVisibility(false);
            setLoading(false);
            setIsLoading(false);
            // setShowAlert(true)
            // setMessage("Ha ocurido un error al aceptar el contrato.");
            //setSeverity("error");
            //setOpenMessage(true);
          });
      }

      if (creditType === "diremovil-promotion") {
        apiClient
          .contratoDiremovilPromotion(contratoRes)
          .then((r) => {
            history.push("/dashboard");
            setNextNav(0);

            setPopupVisibility(false);
            // setShowAlert(true)
          })
          .catch((err) => {
            setNextNav(0);
            history.push("/dashboard");
            setPopupVisibility(false);
            setLoading(false);
            setIsLoading(false);
            // setShowAlert(true)
            // setMessage("Ha ocurido un error al aceptar el contrato.");
            //setSeverity("error");
            //setOpenMessage(true);
          });
      }
    } else {
      
      setError(true)
    }
  };

  const rechazar = async () => {
    setLoading(true);
    const { creditType } = await apiClient.getActiveCredit();

    contratoRes.accepted = false;

    if (creditType === "nomina") {
      apiClient
        .contratoNomina(contratoRes)
        .then((r) => {
          deleteCredito();
          history.push("/solicitud-rechazada-aprobada/nomina");
          setPopupVisibility(false);
          
        })
        .catch((err) => {
          setLoading(false);
          setMessage("Ha ocurido un error al aceptar el contrato.");
          setSeverity("error");
          setOpenMessage(true);
        });
    }

    if (creditType === "personal") {
      apiClient
        .contratoPersonal(contratoRes)
        .then((r) => {
          deleteCredito();
          history.push("/solicitud-rechazada-aprobada/personal");
          setPopupVisibility(false);
        })
        .catch((err) => {
          setLoading(false);
          setMessage("Ha ocurido un error al aceptar el contrato.");
          setSeverity("error");
          setOpenMessage(true);
        });
    }

    if (creditType === "pabs") {
      apiClient
        .contratoPABS(contratoRes)
        .then((r) => {
          deleteCredito();
          history.push("/solicitud-rechazada-aprobada/pabs");
          setPopupVisibility(false);
        })
        .catch((err) => {
          setLoading(false);
          setMessage("Ha ocurido un error al aceptar el contrato.");
          setSeverity("error");
          setOpenMessage(true);
        });
    }
  };
  const onclickDescrgar = async () => {
    const { creditType, id } = await apiClient.getActiveCredit();

    contratoRes.accepted = false;

    if (creditType === "nomina") {
      apiClient
        .dowloandNomina(id)
        .then((r) => {
          const link = document.createElement("a");
          const url = URL.createObjectURL(r);
          console.log(url);
          link.href = url;
          link.download = "contrato.pdf";
          link.click();
        })
        .catch((err) => {
          console.log("err", err);
        });

      apiClient
        .dowloandNominaAmortizacion(id)
        .then((r) => {
          const link = document.createElement("a");
          const url = URL.createObjectURL(r);
          console.log(url);
          link.href = url;
          link.download = "tablaAmortizacion.pdf";
          link.click();
        })
        .catch((err) => {
          console.log("err", err);
        });
    }

    if (creditType === "personal") {
      apiClient
        .dowloandPersonal(id)
        .then((r) => {
          const link = document.createElement("a");
          const url = URL.createObjectURL(r);
          console.log(url);
          link.href = url;
          link.download = "contrato.pdf";
          link.click();
        })
        .catch((err) => {
          console.log("err", err);
        });

      apiClient
        .dowloandPersonalAmortizavion(id)
        .then((r) => {
          const link = document.createElement("a");
          const url = URL.createObjectURL(r);
          console.log(url);
          link.href = url;
          link.download = "tablaAmortizacion.pdf";
          link.click();
        })
        .catch((err) => {
          console.log("err", err);
        });
    }

    if (creditType === "pabs") {
      apiClient
        .dowloandPabs(id)
        .then((r) => {
          const link = document.createElement("a");
          const url = URL.createObjectURL(r);
          console.log(url);
          link.href = url;
          link.download = "contrato.pdf";
          link.click();
        })
        .catch((err) => {
          console.log("err", err);
        });

      apiClient
        .dowloandPabsAmortizacion(id)
        .then((r) => {
          const link = document.createElement("a");
          const url = URL.createObjectURL(r);
          console.log(url);
          link.href = url;
          link.download = "tablaAmortizacion.pdf";
          link.click();
        })
        .catch((err) => {
          console.log("err", err);
        });
    }

    if (creditType === "diremovil") {
      apiClient
        .dowloandContratoDiremovil(id)
        .then((r) => {
          const link = document.createElement("a");
          const url = URL.createObjectURL(r);
          console.log(url);
          link.href = url;
          link.download = "contrato.pdf";
          link.click();
        })
        .catch((err) => {
          console.log("err", err);
        });

      apiClient
        .dowloandDiremovilAmortization(id)
        .then((r) => {
          const link = document.createElement("a");
          const url = URL.createObjectURL(r);
          console.log(url);
          link.href = url;
          link.download = "tablaAmortizacion.pdf";
          link.click();
        })
        .catch((err) => {
          console.log("err", err);
        });

        apiClient.dowloandDiremovilInstructionLetter(id).then(r =>{
          const link = document.createElement('a');
          const url = URL.createObjectURL(r);
          console.log(url);
          link.href = url;
          link.download = 'cartaInstruccion.pdf';
          link.click();
  
        }).catch(err=>{
          console.log('err', err)
        })
    }
  };
  const handleChange = () => {
    setaceptarAnexos(!aceptarAnexos);
    setError(aceptarAnexos)
  };
  const handleClose = () => {
    setOpenMessage(false);
  };
  function screen(width) {
    let height = 0;
    switch (width) {
      case width < 700:
        height = 100;
        break;
      case width > 700:
        height = 300;
    }

    return height;
  }
  return (
    <div>
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />
      <Popup
        visible={isPopupVisible}
        closeOnOutsideClick={false}
         width={"90%"}
         height={"70%"}
        showTitle={false}
        position={{
          at: 'center',
           my: 'center',
        collision: 'none',
        }}   
        onHiding={aceptar}
      >
        <div>
          
        </div>
        <ScrollView width="100%" height="90%">
          <div id="textBlock">
            {/* {
            tipoContratro == null
            ?
            null
            : 
            tipoContratro == 'nomina' ? <ContratoNomina contractInfo={contractInfo}/> : 
            tipoContratro == 'personal' ? <ContratoPersonal contractInfo={contractInfo}/> :
            tipoContratro == 'pabs' ?  <ContratoPABS contractInfo={contractInfo}/>: null
          } */}

            <h3 className="sol1-tittle text-center">
              Para continuar necesitar aceptar el contrato.
            </h3>
            <div className="avisoContentNew">
              <span style={{ fontSize: 14, marginLeft: 1 }}>
                Probenso Group, Sociedad Anónima, Sociedad Financiera de Objeto
                Múltipe, Entidad No Regulada (E.N.R.), con domicilio en Ures Sur
                137, Colonia Centro, Tepic, 63000, Nayarit, México es el
                responsable de sus datos personales. Utilizaremos tus datos
                personales para los siguientes fines indispensables: (i) Crear
                tu cuenta de usuario en la plataforma alojada en
                https://percapita.mx/; (ii) Identificarte como usuario de la
                plataforma alojada en https://percapita.mx/; (iii) Generar el
                expediente de solicitud de tu crédito; (iv) Consultar tu
                historial y capacidad crediticia; (v) Decidir si se aprueba o
                rechaza tu solicitud de crédito, y; (vi) Firmar los contratos de
                crédito que sean aprobados.Para conocer el aviso de privacidad
                integral, por favor haz{" "}
                <a
                  href="/aviso-de-privacidad"
                  target="_blank"
                  style={{ fontSize: 14, marginLeft: 1 }}
                >
                  click aquí.
                </a>
              </span>
            </div>
            <div className="avisoContentNew" style={{ marginTop: 20 }}>
              <label style={{ marginLeft: 5 }}>
                <input
                  style={{ width: "22px" }}
                  type="checkbox"
                  checked={aceptarAnexos}
                  onChange={handleChange}
                />
                <span>
                  {" "}
                  Confirmo que he leído y me obligo a cumplir con el{" "}
                </span>
              </label>
              <a
                onClick={onclickDescrgar}
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  paddingLeft: "5px",
                }}
              >
                Contrato de Crédito.
              </a>
              {error && (
                  <div className="" style={{color: "red",fontWeight:"bold"}}>
                    Debes aceptar el contrato
                  </div>
                )}
            </div>
            <div className="row">
              {/* <div className="col-12 col-sm-12 col-md-12 col-lg-4"></div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2">
                <button className="btn btn-primary" type="submit" onClick={aceptar} disabled={loading}>
                Aceptar
                  </button>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2">
                <button className="btn btn-danger" type="submit" onClick={rechazar} disabled={loading}>
                    Rechazar 
                  </button>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-4"></div>
                </div> */}
              <div className="d-flex justify-content-around mt-3">
                <button
                  type="submit"
                  className="btn btn-shadow"
                  onClick={aceptar}
                >
                  Aceptar
                </button>
                <button
                  type="submit"
                  className="btn btn-shadow-cancela"
                  onClick={rechazar}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </ScrollView>
        <Backdrop
          sx={{ color: "#173A5E", zIndex: (theme) => theme.zIndex.appBar + 1 }}
          open={isLoading}
        >
          <img
            src={logo_balnco}
            alt="PRADO'S"
            className="img-fluid  img-client"
            width="300"
          />
          <CircularProgress color="primary" size={80} />
        </Backdrop>
      </Popup>
    </div>
  );
}
