import React, { useEffect, useState } from 'react';

export const DiremovilContext = React.createContext();

const UserDiremovilProvider = props => {
    const [userDiremovil, setUserDiremovil] = useState({
        userId: '',
        nombre: '',
      });
    
      const [ubicacion, setUbicacion] = useState(null);
      const updateUserDiremovil = valor => {
        setUserDiremovil(valor);
        localStorage.setItem('userVentaDiremovil', JSON.stringify(valor));
      };
    
      useEffect(() => {
        if (localStorage.getItem('userVentaDiremovil')) {
          const userLocal = JSON.parse(localStorage.getItem('userVentaDiremovil'));
          setUserDiremovil(userLocal);
        }
      }, []);



    
      return (
        <DiremovilContext.Provider value={{userDiremovil, updateUserDiremovil,ubicacion, setUbicacion}}>
          {props.children}
        </DiremovilContext.Provider>
      );
    };
    
    UserDiremovilProvider.propTypes = {};
    
    export default UserDiremovilProvider;