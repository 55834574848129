import React, { useEffect, useState, useRef } from "react";
import logo_balnco from "../../../img/logo_blanco.png";
import fondo_1 from "../../../img/fondo_1.png";
import fondo_2 from "../../../img/fondo_2.png";
import Footer from "../../front/FooterI";
import paso_1 from "../../../img/paso_1.png";
import paso_2 from "../../../img/paso_2.png";
import paso_3 from "../../../img/paso_3.png";
import paso_4 from "../../../img/paso_4.png";
import { useFormik } from "formik";
import { get } from "http";
import {
  AnnuaLRate,
  AnnuaLRateEmployee,
  Iva,
  CreateAmortizationInterface,
} from "./enums/amortization";
import moment from "moment";
import ApiClient from "../../../services/apiclient";
import { useHistory } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { nextStep } from "../../../store/actions/PrestamosPersonalesActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { motion } from "framer-motion";
import Navbar from "../../front/NavbarI";
import { useAnimation, useInView } from "framer-motion";
import { CSSTransition } from "react-transition-group";
import { PreTramiteCreditoContext } from "../../../context/PreTramiteCreditoProvider";
import LoanType from "../../loan-type.component";
const SolicitudConfiguracion = ({ nominaId, nextStep }) => {

  localStorage.getItem("loanType");

  const [change, setChange] = useState(false);
  const nodeRef = useRef(null);
  const [change2, setChange2] = useState(false);
  const nodeRef2 = useRef(null);
  const [change3, setChange3] = useState(false);
  const nodeRef3 = useRef(null);
  const [change4, setChange4] = useState(false);
  const nodeRef4 = useRef(null);
  const [tasa, setTasa] = useState("70%");
  const [plazo, setPlazo] = useState("Semanal");
  const [montos, setMontos] = useState([]);
  const [minPlazo, setMinPlazo] = useState(12);
  const [maxPlazo, setMaxPlazo] = useState(52);
  const [pagoNeto, setPagoNeto] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const apiClient = ApiClient.getInstance();
  const [rating, setRating] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [paramsP, setParamsP] = useState([]);
  const [plazoF, setPlazoF] = useState(0);
  const [step, setStep] = useState(1);
  const history = useHistory();
  const { preCredito, updatePreCredito, deletePreCredito } = React.useContext(
    PreTramiteCreditoContext
  );
  const params = [
    {
      rate: 1,
      renovacion: false,
      minWeeks: 12,
      maxWeeks: 52,
      minBiweekly: 6,
      maxBiweekly: 24,
      tasa: "70%",
      comission: 5,
      iva: 16,
      tasaNumber: 70,
      maxAmount: 15000,
    },
    {
      rate: 2,
      renovacion: false,
      minWeeks: 12,
      maxWeeks: 52,
      minBiweekly: 6,
      maxBiweekly: 24,
      tasa: "110%",
      comission: 5,
      iva: 16,
      tasaNumber: 110,
      maxAmount: 10000,
    },
    {
      rate: 3,
      renovacion: false,
      minWeeks: 12,
      maxWeeks: 24,
      minBiweekly: 6,
      maxBiweekly: 12,
      tasa: "150%",
      comission: 5,
      iva: 16,
      tasaNumber: 150,
      maxAmount: 5000,
    },
    {
      rate: 4,
      renovacion: false,
      minWeeks: 12,
      maxWeeks: 15,
      minBiweekly: 6,
      maxBiweekly: 8,
      tasa: "300%",
      comission: 0,
      iva: 16,
      tasaNumber: 300,
      maxAmount: 3000,
    },
    {
      rate: 1,
      renovacion: true,
      minWeeks: 12,
      maxWeeks: 52,
      minBiweekly: 6,
      maxBiweekly: 24,
      tasa: "70%",
      comission: 5,
      iva: 16,
      tasaNumber: 70,
      maxAmount: 20000,
    },
    {
      rate: 2,
      renovacion: true,
      minWeeks: 12,
      maxWeeks: 52,
      minBiweekly: 6,
      maxBiweekly: 24,
      tasa: "110%",
      comission: 5,
      iva: 16,
      tasaNumber: 110,
      maxAmount: 10000,
    },
    {
      rate: 3,
      renovacion: true,
      minWeeks: 12,
      maxWeeks: 24,
      minBiweekly: 6,
      maxBiweekly: 12,
      tasa: "150%",
      comission: 5,
      iva: 16,
      tasaNumber: 150,
      maxAmount: 5000,
    },
    {
      rate: 4,
      renovacion: true,
      minWeeks: 12,
      maxWeeks: 15,
      minBiweekly: 6,
      maxBiweekly: 8,
      tasa: "300%",
      comission: 0,
      iva: 16,
      tasaNumber: 300,
      maxAmount: 3000,
    },
    {
      rate: 5,
      renovacion: true,
      minWeeks: 12,
      maxWeeks: 24,
      minBiweekly: 6,
      maxBiweekly: 12,
      tasa: "270%",
      comission: 0,
      iva: 16,
      tasaNumber: 270,
      maxAmount: 15000,
    },
    {
      rate: 5,
      renovacion: true,
      minWeeks: 12,
      maxWeeks: 24,
      minBiweekly: 6,
      maxBiweekly: 12,
      tasa: "270%",
      comission: 0,
      iva: 16,
      tasaNumber: 270,
      maxAmount: 3000,
    },
  ];

  const amounts = [
    { amount: 2000 },
    { amount: 3000 },
    { amount: 4000 },
    { amount: 5000 },
    { amount: 6000 },
    { amount: 7000 },
    { amount: 8000 },
    { amount: 9000 },
    { amount: 10000 },
    { amount: 11000 },
    { amount: 12000 },
    { amount: 13000 },
    { amount: 14000 },
    { amount: 15000 },
    { amount: 16000 },
    { amount: 17000 },
    { amount: 18000 },
    { amount: 19000 },
    { amount: 20000 },
  ];
  const getAmounts = async () => {
    const activeCredit = await apiClient.getActiveCredit();
    const credit = await apiClient.getCredit(activeCredit.creditType);
    console.log(credit);
    setTasa(
      credit.customerRating === 1
        ? "70%"
        : credit.customerRating === 2
        ? "110%"
        : credit.customerRating === 3
        ? "150%"
        : credit.customerRating === 4
        ? "300%"
        : "270%"
    );
    setRating(credit.customerRating);
    let init : any = [];
    if(credit.amountLimit > 0){
       init = params.filter(
        (p) =>
          p.rate === credit.customerRating &&
          p.renovacion ===
            (credit.user.isRenovacion === null ||
            credit.user.isRenovacion === false
              ? false
              : true) &&
          p.maxAmount === credit.amountLimit
      );
    } else {
       init = params.filter(
        (p) =>
          p.rate === credit.customerRating &&
          p.renovacion ===
            (credit.user.isRenovacion === null ||
            credit.user.isRenovacion === false
              ? false
              : true)
      );
    }
    setMaxAmount(init[0].maxAmount);
    setParamsP(init);
    setFieldValue("amount", init[0].maxAmount);
    setMinPlazo(
      values.plazo === "Semanal" ? init[0].minWeeks : init[0].minBiweekly
    );
    setMaxPlazo(
      values.plazo === "Semanal" ? init[0].maxWeeks : init[0].maxBiweekly
    );
    var a: CreateAmortizationInterface = {
      amount: init[0].maxAmount,
      paymentTerm: values.numberPayments,
      recurrencePayments: values.plazo === "Semanal" ? 3 : 2,
      customerRating: credit.customerRating,
    };
    console.log(a);
    setPagoNeto(createAmortization(a));
    const filt = amounts.filter((a) => a.amount <= init[0].maxAmount);
    setMontos(filt);
  };
  useEffect(() => {
    getAmounts();
  }, []);
  const animation = useAnimation();
  useEffect(() => {
    animation.start({
      y: "50%",
      opacity: 0.5,
      scale: 0.5,
      transition: { ease: "easeInOut", duration: 0.1 },
    });
    setTimeout(() => animation.stop, 1);
  }, [plazoF]);
  useEffect(() => {
    const checkUserVeriffValidated = async () => {
      const isValidatedRes = await apiClient.checkUserVeriffValidated();
      console.log(isValidatedRes);
      if (isValidatedRes.veriffValidated !== true) {
        history.push("/veriff?from=prestamo-personal");
        return;
      }
    };
    const timeout = setTimeout(() => {
      checkUserVeriffValidated();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  const updateMarketing = async (id: any, data: any) => {
    await apiClient.updateMarketingTrack(id, data);
  };
  useEffect(() => {
    if (localStorage.getItem("preCreditoLocal")) {
      var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
      if (
        credito.stepMarketing != "configuration-credit-init" &&
        credito.typeLoanMarketing === "Personal"
      )
        var data = {
          processName: "configuration-credit-init",
        };
      updatePreCredito({
        ...preCredito,
        stepMarketing: "configuration-credit-init",
      });
      updateMarketing(credito.idProcessMarketing, data);
    }
  }, []);
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      amount: 0,
      plazo: "Semanal",
      numberPayments: 12,
    },
    onSubmit: (values) => {
      setIsLoading(true);
      const body = {
        amount: values.amount,
        numberPayments: values.numberPayments,
        frequencyPayments: values.plazo,
      };

      apiClient.getCredit("personal").then((credit) => {
        apiClient
          .updateCreditSettings("personal", body, credit.id)
          .then((data) => {
            if (localStorage.getItem("preCreditoLocal")) {
              var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
              if (
                credito.idProcessMarketing !== "" &&
                credito.typeLoanMarketing === "Personal"
              ) {
                var dd = {
                  processName: "configuration-credit-finish",
                  amount: values.amount,
                };
                updatePreCredito({
                  ...preCredito,
                  stepMarketing: "configuration-credit-finish",
                });
                updateMarketing(credito.idProcessMarketing, dd);
              }
            }
            nextStep("LABOR-DATA");
            //   setTimeout(() => {
            //     setIsLoading(false)
            //     history.push('/proceso-credito');
            //   }, 1500);
          })
          .catch((err) => {});
      });
    },
  });
  function handleChangeAmount(event: any) {
    setChange2(!change2);
    setChange4(!change4);
    setFieldValue("amount", parseInt(event.target.value));
    var a: CreateAmortizationInterface = {
      amount: parseInt(event.target.value),
      paymentTerm: values.numberPayments,
      recurrencePayments: values.plazo === "Semanal" ? 3 : 2,
      customerRating: rating,
    };
    setPagoNeto(createAmortization(a));
  }
  function handleChangePlazo(event: any) {
    setChange(!change);
    setChange2(!change2);
    setChange3(!change3);
    setChange4(!change4);
    setFieldValue("plazo", event.target.value);
    if(rating === 4){
      setStep(rating === 4 ? values.plazo === "Semanal" ? 3 : 2: values.plazo === "Semanal" ? 4 : 2);
    }
    setMinPlazo(
      event.target.value === "Semanal"
        ? paramsP[0].minWeeks
        : paramsP[0].minBiweekly
    );
    setMaxPlazo(
      event.target.value === "Semanal"
        ? paramsP[0].maxWeeks
        : paramsP[0].maxBiweekly
    );
    setFieldValue(
      "numberPayments",
      event.target.value === "Semanal"
        ? paramsP[0].minWeeks
        : paramsP[0].minBiweekly
    );
    var a: CreateAmortizationInterface = {
      amount: values.amount,
      paymentTerm:
        event.target.value === "Semanal"
          ? paramsP[0].minWeeks
          : paramsP[0].minBiweekly,
      recurrencePayments: event.target.value === "Semanal" ? 3 : 2,
      customerRating: rating,
    };
    setPagoNeto(createAmortization(a));
  }
  function handleChangeNumberPayments(event: any) {
    setChange(!change);
    setChange2(!change2);
    setChange3(!change3);
    setChange4(!change4);
    setPlazoF(parseInt(event.target.value));
    setFieldValue("numberPayments", parseInt(event.target.value));
    setStep(rating === 4 ? values.plazo === "Semanal" ? 3 : 2: values.plazo === "Semanal" ? 4 : 2);
    var a: CreateAmortizationInterface = {
      amount: values.amount,
      paymentTerm: parseInt(event.target.value),
      recurrencePayments: values.plazo === "Semanal" ? 3 : 2,
      customerRating: rating,
    };
    setPagoNeto(createAmortization(a));
  }
  function testAmortizacion(){
    var a: CreateAmortizationInterface = {
      amount: 50000,
      paymentTerm: 24,
      recurrencePayments:  2,
      customerRating: 5,
    };
    console.log(createAmortization(a));
  }

  function createAmortization(data: CreateAmortizationInterface) {
    const { amount, paymentTerm, recurrencePayments, customerRating } = data;

    var annualRate: number = 0;
    var commission: number = 0;
    var ivaCommission: number = 0;
    var netRate: number = 0;

    annualRate =
      customerRating === 1
        ? AnnuaLRate.TasaA
        : customerRating === 2
        ? AnnuaLRate.TasaB
        : customerRating === 3
        ? AnnuaLRate.TasaC
        : customerRating === 4
        ? AnnuaLRate.TasaD
        : AnnuaLRate.TasaE;

    let multiply: number = amount * 0.05;
    // commission = multiply / paymentTerm;
    // commission = Number(commission.toFixed(2));
    // ivaCommission = Number(commission * Iva.decimal);
    // ivaCommission = Number(ivaCommission.toFixed(2));
    commission = customerRating === 4 || customerRating === 5 ? 0 : multiply / paymentTerm
    commission = customerRating === 4 || customerRating === 5 ? 0 : Number(commission.toFixed(2))
    //Multipliacndo la comision por el IVA.
    ivaCommission = customerRating === 4 || customerRating === 5 ? 0 : Number(commission * Iva.decimal)
    ivaCommission = customerRating === 4 || customerRating === 5 ? 0 : Number(ivaCommission.toFixed(2))

    if (recurrencePayments === 2) {
      //Validar si las recurrencia de pago es igual a quincenal
      let divide: number = annualRate / 24;
      netRate = divide * 1.16;
    } else if (recurrencePayments === 3) {
      //Validar si la recurrencia de pago es igual a semanal
      let divide: number = annualRate / 52;
      netRate = divide * 1.16;
    }
    var netRatePlus: number = 1 + netRate;
    var exponent: number = Math.pow(netRatePlus, paymentTerm);
    var multipli: number = netRate * exponent;
    var operationSubtract: number = exponent - 1;
    var divide: number = multipli / operationSubtract;
    var pay: number = amount * divide;
    pay = parseFloat(pay.toFixed(2));
    var pendingPayment: number = amount;
    var cumulative_total = amount;
    var payment_date = moment(new Date());
    let interests: number = netRate * pendingPayment;
    interests = parseFloat(interests.toFixed(2));
    let paymentCapital: number = pay - interests;
    paymentCapital = parseFloat(paymentCapital.toFixed(2));
    let fullPayment: number = parseFloat(
      (paymentCapital + interests + commission + ivaCommission).toFixed(2)
    );
    return fullPayment;
  }
  useEffect(() => {
    console.log('changed', rating,values.plazo)
    if(rating === 4){
      setStep( values.plazo === "Semanal" ? 3 : 2);
    }
  },[rating])

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.6 }}
      className="blue-background-mess"
    >
      <Navbar isLoggedIn />
      <br />
      <LoanType
        loanType={localStorage.getItem("loanType")}
        userValidated={true}
      />
      <div className="d-none d-sm-none d-md-block p-0 m-0 ">
        <div className="row">
          <motion.div
            initial={{ x: "50%", opacity: 0, scale: 0.5 }}
            animate={{ x: 0, opacity: 1, scale: 1 }}
            exit={{ x: "50%", transition: { duration: 0.8 } }}
            transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
            className="col-2"
          >
            <img
              src={fondo_2}
              alt="PRADO'S"
              className="img-fluid img-top-sol8"
              width="250"
            />
          </motion.div>
          <div className="col-8">
            <motion.div
              initial={{ x: 1000, opacity: 0, scale: 0.5 }}
              animate={{ x: 0, opacity: 1, scale: 1 }}
              exit={{ x: "200%", transition: { duration: 0.8 } }}
              transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
              className="d-flex justify-content-center mb-1"
            >
              <img
                src={logo_balnco}
                alt="PRADO'S"
                className="img-fluid p-2 img-client mt-5 mb-4"
                width="240"
              />
            </motion.div>
            <motion.div
              initial={{ y: "50%", opacity: 0, scale: 0.5 }}
              animate={{ y: 0, opacity: 1, scale: 1 }}
              exit={{ y: "50%", transition: { duration: 0.8 } }}
              transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
              className="text-white text-center"
            >
              <h1 className="mt-2 text-tittle">¡Felicidades!</h1>
              <h4 className="mt-3">
                Tu crédito ya está Pre-autorizado <br />
                hasta{" "}
                <span className="text-blue-light">
                  {maxAmount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </span>
              </h4>
            </motion.div>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-3"></div>
                <motion.div
                  initial={{ y: "50%", opacity: 0, scale: 0.5 }}
                  animate={{ y: 0, opacity: 1, scale: 1 }}
                  exit={{ y: "50%", transition: { duration: 0.8 } }}
                  transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
                  className="col-12 col-sm-12 col-md-12 col-lg-6"
                >
                  <motion.div
                    initial={{ y: "50%", opacity: 0, scale: 0.5 }}
                    animate={{ y: 0, opacity: 1, scale: 1 }}
                    exit={{ y: "50%", transition: { duration: 0.8 } }}
                    transition={{
                      ease: "easeInOut",
                      duration: 0.8,
                      delay: 0.4,
                    }}
                    className="mb-3"
                  >
                    
                    <img
                      src={paso_1}
                      alt="PRADO'S"
                      className="img-fluid p-2 img-client"
                      width="50"
                    />
                    <label htmlFor="amount" className="form-label white-tittle">
                      Elige el monto de tu credito *
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={handleChangeAmount}
                      id="amount"
                      value={values.amount}
                      onBlur={handleChangeAmount}
                    >
                      <option selected>Seleccione una opcion</option>
                      {montos.map((option) => (
                        <option value={option.amount}>${option.amount}</option>
                      ))}
                    </select>
                  </motion.div>
                  <motion.div
                    initial={{ y: "50%", opacity: 0, scale: 0.5 }}
                    animate={{ y: 0, opacity: 1, scale: 1 }}
                    exit={{ y: "50%", transition: { duration: 0.8 } }}
                    transition={{
                      ease: "easeInOut",
                      duration: 0.8,
                      delay: 0.4,
                    }}
                    className="mb-3"
                  >
                    <img
                      src={paso_2}
                      alt="PRADO'S"
                      className="img-fluid p-2 img-client"
                      width="50"
                    />
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label white-tittle"
                    >
                      Plazo *
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      id="plazo"
                      onChange={handleChangePlazo}
                      value={values.plazo}
                      onBlur={handleChangePlazo}
                    >
                      <option selected>Plazo</option>
                      <option value="Semanal">Semanal</option>
                      <option value="Quincenal">Quincenal</option>
                    </select>
                  </motion.div>
                  <img
                    src={paso_3}
                    alt="PRADO'S"
                    className="img-fluid p-2 img-client"
                    width="50"
                  />
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label white-tittle"
                  >
                    Duracion *
                  </label>
                  <div className="container-range text-center">
                    <input
                      type="range"
                      className="slider"
                      min={minPlazo}
                      max={maxPlazo}
                      step={step}
                      value={values.numberPayments}
                      id="numberPayments"
                      onChange={handleChangeNumberPayments}
                    />
                  </div>
                  <div className="row mt-2 text-center text-white">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 p-4">
                      <h4 className="mt-2">
                        Pagos <br /> en
                      </h4>
                      <CSSTransition
                        nodeRef={nodeRef}
                        in={change}
                        timeout={200}
                        classNames="my-node"
                      >
                        <div ref={nodeRef}>
                          <h5 className="text-blue-light">
                            {values.numberPayments}{" "}
                            {values.plazo === "Semanal"
                              ? "Semanas"
                              : "Quincenas"}
                          </h5>
                        </div>
                      </CSSTransition>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 p-4">
                      <h4 className="mt-2">
                        Tasa <br /> promedio
                      </h4>
                      <h5 className="text-blue-light">{tasa}</h5>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 p-4">
                      <h4 className="mt-2">
                        Tu <br /> pago
                      </h4>
                      <CSSTransition
                        nodeRef={nodeRef2}
                        in={change2}
                        timeout={200}
                        classNames="my-node"
                      >
                        <div ref={nodeRef2}>
                          <h5 className="text-blue-light">
                            {pagoNeto.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </h5>
                        </div>
                      </CSSTransition>

                      <small className="text-blue-light">
                        {values.plazo === "Semanal" ? "Semanal" : "Quincenal"}
                      </small>
                    </div>
                  </div>

                  <div className="text-start text-white d-flex mt-3">
                    <img
                      src={paso_4}
                      alt="PRADO'S"
                      className="img-fluid p-2 img-client"
                      width="50"
                    />
                    <label className="">
                      Por último necesitamos validar tu identidad
                    </label>
                  </div>
                </motion.div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-3"></div>
              </div>
              <div className="d-flex justify-content-center mt-2 mb-4">
                <button type="submit" className="btn btn-continue-large mt-5">
                  Comenzar validación
                </button>
              </div>
            </form>
          </div>
          <div className="col-2">
            <img
              src={fondo_1}
              alt="PRADO'S"
              className="img-fluid img-top2-sol8"
              width="260"
            />
          </div>
        </div>
      </div>
      <div className="d-block d-sm-block d-md-none p-0 m-0 ">
        <div className="d-flex justify-content-center mb-1">
          <img
            src={logo_balnco}
            alt="PRADO'S"
            className="img-fluid p-2 img-client mt-5 mb-4"
            width="240"
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="text-white text-center container">
            <h1 className="mt-1 text-tittle">¡Felicidades!</h1>
            <h4 className="mt-2">
              Tu crédito ya está Pre-autorizado <br />
              hasta{" "}
              <span className="text-blue-light">
                {maxAmount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            </h4>
            <div className="mb-3">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label white-tittle"
              >
                Elige el monto de tu credito*
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={handleChangeAmount}
                id="amount"
                value={values.amount}
                onBlur={handleChangeAmount}
              >
                <option selected>Seleccione una opcion</option>
                {montos.map((option) => (
                  <option value={option.amount}>${option.amount}</option>
                ))}
              </select>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label white-tittle"
                >
                  Plazo*
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  id="plazo"
                  onChange={handleChangePlazo}
                  value={values.plazo}
                  onBlur={handleChangePlazo}
                >
                  <option selected>Plazo</option>
                  <option value="Semanal">Semanal</option>
                  <option value="Quincenal">Quincenal</option>
                </select>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                <div className="container-range text-center">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label white-tittle"
                  >
                    Duracion *
                  </label>
                  <input
                    type="range"
                    className="slider"
                    min={minPlazo}
                    max={maxPlazo}
                    step={step}
                    value={values.numberPayments}
                    id="numberPayments"
                    onChange={handleChangeNumberPayments}
                  />
                </div>
              </div>
            </div>
            <hr />
            <h4 className="mt-2">Pagos en</h4>
            <CSSTransition
              nodeRef={nodeRef3}
              in={change3}
              timeout={200}
              classNames="my-node"
            >
              <div ref={nodeRef3}>
                <h5 className="text-blue-light">
                  {values.numberPayments}{" "}
                  {values.plazo === "Semanal" ? "Semanas" : "Quincenas"}
                </h5>
              </div>
            </CSSTransition>
            <hr />
            <h4 className="mt-2">Tasa promedio</h4>
            <h5 className="text-blue-light">{tasa}</h5>
            <hr />
            <h4 className="mt-2">Tu pago</h4>
            <CSSTransition
              nodeRef={nodeRef4}
              in={change4}
              timeout={200}
              classNames="my-node"
            >
              <div ref={nodeRef4}>
                <h5 className="text-blue-light">
                  {pagoNeto.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </h5>
                <small className="text-blue-light">
                  {values.plazo === "Semanal" ? "Semanal" : "Quincenal"}
                </small>
              </div>
            </CSSTransition>

            <hr />
            <div className="d-flex justify-content-center mt-2 mb-4">
              <button type="submit" className="btn btn-continue mt-5 mb-5">
                Continuar
              </button>
            </div>
          </div>
        </form>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.appBar + 1 }}
        open={isLoading}
      >
        <img
          src={logo_balnco}
          alt="PRADO'S"
          className="img-fluid p-3 img-client"
          width="300"
        />
        <CircularProgress color="primary" size={80} />
      </Backdrop>
      <Footer />
    </motion.div>
  );
};

const mapStateToProps = ({ personalCreditReducer }) => ({
  user: personalCreditReducer?.user ?? {},
  nominaId: personalCreditReducer?.nominaId ?? "",
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      nextStep,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SolicitudConfiguracion);
