import React from 'react'
import logo_balnco from '../../../img/logo_blanco.png';
import fondo_1 from '../../../img/fondo_1.png';
import fondo_2 from '../../../img/fondo_2.png';
import Navbar from '../../front/NavbarI';
import Footer from '../../front/FooterI';
import paso_1 from '../../../img/paso_1.png';
import paso_2 from '../../../img/paso_2.png';
import paso_3 from '../../../img/paso_3.png';
import whats from '../../../img/whats.png';
import cel from '../../../img/cel.png';

export default function Solicitud9(){
    return (
        <div className="blue-background-mess">
            <Navbar />
            <div className="d-none d-sm-none d-md-block p-0 m-0 ">
                <div className="row">
                    <div className="col-3">
                        <img src={fondo_2} alt="PRADO'S" className="img-fluid img-top-sol8" width="250" />
                    </div>
                    <div className="col-6">
                        <div className="d-flex justify-content-center mb-1">
                            <img src={logo_balnco} alt="PRADO'S" className="img-fluid p-2 img-client mt-5 mb-4" width="240" />
                        </div>
                        <div className="text-white text-center">
                            <h1 className="mt-1 text-tittle">Lo sentimos :(</h1>
                            <h4 className="mt-3">No pudimos validar tu información</h4>
                        </div>
                        <div className="row mt-4 text-white">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-2">
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                                <div className="d-flex">
                                    <img src={paso_1} alt="PRADO'S" className="img-fluid p-2 img-client" width="50" />
                                    <h6 className="mt-3">Verifica que tus datos sean correctos</h6>
                                </div>
                                <div className="d-flex">
                                    <img src={paso_2} alt="PRADO'S" className="img-fluid p-2 img-client" width="50" />
                                    <h6 className="mt-3">No se ha podido continuar con la solicitud</h6>
                                </div>
                                <div className="d-flex">
                                    <img src={paso_3} alt="PRADO'S" className="img-fluid p-2 img-client" width="50" />
                                    <h6 className="mt-3">Comunícate con nosotros:</h6>
                                </div>
                                <div className="row mt-5">
                                    <div className="col text-center text-white">
                                        <button className="btn btn-sm text-white btn-border">
                                            <img src={cel} alt="PRADO'S" className="img-fluid mrirht2" width="15" />
                                            33 3616-8059
                                        </button>
                                    </div>
                                    <div className="col text-center">
                                        <button className="btn btn-sm text-white btn-border">
                                            <img src={whats} alt="PRADO'S" className="img-fluid mrirht2" width="20" />
                                            
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-2">
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-2 mb-4">
                            <a href='/solicitud/personal/10' className="btn btn-continue-large mt-5">
                                Regresar
                            </a>
                        </div>

                    </div>
                    <div className="col-3">
                        <img src={fondo_1} alt="PRADO'S" className="img-fluid img-top2-sol8" width="260" />
                    </div>
                </div>
            </div>
            <div className="d-block d-sm-block d-md-none p-0 m-0 ">
            <div className="row">
                    <div className="col-3">
                        <img src={fondo_2} alt="PRADO'S" className="img-fluid img-top-sol8" width="250" />
                    </div>
                    <div className="col-6">
                        <div className="d-flex justify-content-center mb-1">
                            <img src={logo_balnco} alt="PRADO'S" className="img-fluid p-2 img-client mt-5 mb-4" width="240" />
                        </div>
                        <div className="text-white text-center">
                            <h1 className="mt-1">Lo sentimos :(</h1>
                            <h4 className="mt-1">No pudimos validar tu información</h4>
                        </div>
                        <div className="row mt-4 text-white">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-2">
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                                <div className="d-flex">
                                    <img src={paso_1} alt="PRADO'S" className="img-fluid p-2 img-client" width="90" />
                                    <h6 className="mt-3">Verifica que tus datos sean correctos</h6>
                                </div>
                                <div className="d-flex">
                                    <img src={paso_2} alt="PRADO'S" className="img-fluid p-2 img-client" width="90" />
                                    <h6 className="mt-3">No se ha podido continuar con la solicitud</h6>
                                </div>
                                <div className="d-flex">
                                    <img src={paso_3} alt="PRADO'S" className="img-fluid p-2 img-client" width="60" />
                                    <h6 className="mt-3">Comunícate con nosotros:</h6>
                                </div>
                                <div className="row mt-5">
                                    <div className="col text-center text-white">
                                        <button className="btn btn-sm text-white btn-border">
                                            <img src={cel} alt="PRADO'S" className="img-fluid mrirht2" width="15" />
                                            33 3616-8059
                                        </button>
                                    </div>
                                    <div className="col text-center">
                                        <button className="btn btn-sm text-white btn-border">
                                            <img src={whats} alt="PRADO'S" className="img-fluid mrirht2" width="20" />
                                            
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-2">
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-2 mb-4">
                            <a href='/prestamo-personal' className="btn btn-continue-large mt-5">
                                Regresar
                            </a>
                        </div>

                    </div>
                    <div className="col-3">
                        <img src={fondo_1} alt="PRADO'S" className="img-fluid img-top2-sol8" width="260" />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
