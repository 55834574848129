import React, { useRef, useState } from 'react';
import  Footer  from '../../front/FooterI'
import  Navbar  from '../../front/NavbarI'
import icon_percapita from '../../../img/icon-percapita.png';
import educacion_1 from '../../../img/educacion-1.png';
import icono_calendario from '../../../img/icono-calendario.png';
import finanzas_2 from '../../../img/finanzas-2.png';
import finanzas_6 from '../../../img/finanzas-6.png';
import finanzas_4 from '../../../img/finanzas-4.png';
import educacion_2 from '../../../img/educacion-2.png';
import finanzas_3 from '../../../img/finanzas-3.png';
import finanzas_1 from '../../../img/finanzas-1.png';
import { motion, useAnimation, useInView } from 'framer-motion';
import AlertMessage from '../../alert.component';

export default function CatYComisiones() {
  const [isLoading, setIsLoading] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const mCSS = `
    .tg  {border-collapse:collapse;border-spacing:0;}
    .tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
      overflow:hidden;padding:10px 5px;word-break:normal;}
    .tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;
      font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}
    .tg .tg-9wq8{border-color:inherit;text-align:center;vertical-align:middle}
    .tg .tg-09wn{background-color:#246da2;border-color:inherit;color:#ffffff;font-weight:bold;text-align:center;vertical-align:middle}
    .tg .tg-c3ow{border-color:inherit;text-align:center;vertical-align:top}
    .tg .tg-uzvj{border-color:inherit;font-weight:bold;text-align:center;vertical-align:middle}
  `
 
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };
  const ref = useRef(null);
  const ref2 = useRef(null);
  const inView = useInView(ref);
  const inView2 = useInView(ref2);
  const animation = useAnimation();
  const animation2 = useAnimation();
  const animation3 = useAnimation();
  const animation4 = useAnimation();
  /*useEffect(() => {
    if (inView) {
      animation.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 0.6, delay: 0.3 },
      });

      animation2.start({
        x: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 0.6, delay: 0.3 },
      });
    }
    if (!inView) {
      animation.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation2.start({
        x: "1%",
        opacity: 0,
        scale: 0.5,
      });
    }
  }, [inView]);*/
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.6 }}
    >
      <Navbar />
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />
      <div class="bg-blog">
        <div class="container">
          <div class="row ">
            <div class="text-center pt-1">
                <img
                  src={icon_percapita}
                  alt="PERCAPITA"
                  class="img-fluid  icono-headers"
                />
                <h1 class="rBlack display-4 mt-0">Cat y Comisiones</h1>
              </div>
          </div>
        </div>
      </div>
      <div class="container py-1">
        <div class="row pb-5">
          <div class="col-12 col-sm-12 col-md-12 col-lg-1"></div>
          <div class="col-12 col-sm-12 col-md-12 col-md-lg-11">
            <div class="row" ref={ref}>
              <motion.div
                animate={animation2}
                class="col-12 col-md-12 col-md-12 col-lg-12 pt-5"
              >
                <div class="mx-2">
                  <div
                    class="bg-gris py-5"
                    style={{  overflowX: "auto" }}
                  >
                    <div class="mx-5">
                    <style type="text/css">
                       {mCSS}
                      </style>
                    <table class="tg"><thead>
                          <tr>
                            <th class="tg-09wn" colspan="8">Información de Comisiones de Nuestros Productos</th>
                          </tr></thead>
                        <tbody>
                          <tr>
                            <td class="tg-uzvj" colspan="2">Nuestros Productos</td>
                            <td class="tg-uzvj">Concepto</td>
                            <td class="tg-uzvj">Importe de Comisión</td>
                            <td class="tg-uzvj">Referencia</td>
                            <td class="tg-uzvj">Tasa de interés</td>
                            <td class="tg-uzvj">Tasa de interés moratoria</td>
                            <td class="tg-uzvj">CAT Promedio sin IVA anual. Calculado al 01 de enero del 2024. Vigencia al 31 de mayo del 2024</td>
                          </tr>
                          <tr>
                            <td class="tg-9wq8">2874-140-013302/07-00167-0123</td>
                            <td class="tg-9wq8">Crédito personal sin garantía</td>
                            <td class="tg-9wq8">Comisión por contratación o apertura de línea de crédito</td>
                            <td class="tg-9wq8">1 %</td>
                            <td class="tg-9wq8">Sobre monto o línea de crédito por única vez</td>
                            <td class="tg-9wq8">36.00%</td>
                            <td class="tg-9wq8">0.00%</td>
                            <td class="tg-9wq8">43.2%</td>
                          </tr>
                          <tr>
                            <td class="tg-9wq8">2874-140-008842/11-00168-0123</td>
                            <td class="tg-9wq8">Crédito personal con garantía</td>
                            <td class="tg-9wq8">El producto no prevé comisión alguna</td>
                            <td class="tg-9wq8">-</td>
                            <td class="tg-9wq8">-</td>
                            <td class="tg-9wq8">85.39%</td>
                            <td class="tg-9wq8">0.00%</td>
                            <td class="tg-9wq8">334.8%</td>
                          </tr>
                          <tr>
                            <td class="tg-9wq8">2874-138-033587/03-00119-0123</td>
                            <td class="tg-9wq8">Crédito Hipotecario ARTPARK JMMB</td>
                            <td class="tg-9wq8">Comisión por apertura de línea de crédito</td>
                            <td class="tg-9wq8">0.50%</td>
                            <td class="tg-9wq8">Sobre monto o línea de crédito por única vez</td>
                            <td class="tg-9wq8">10.65%</td>
                            <td class="tg-9wq8">0.00%</td>
                            <td class="tg-9wq8">13.5%</td>
                          </tr>
                          <tr>
                            <td class="tg-9wq8">2874-138-033586/03-00158-0123</td>
                            <td class="tg-9wq8">Crédito Hipotecario JMMB</td>
                            <td class="tg-9wq8">Comisión por apertura de línea de crédito</td>
                            <td class="tg-9wq8">1.50%</td>
                            <td class="tg-9wq8">Sobre monto o línea de crédito por única vez</td>
                            <td class="tg-9wq8">20.65%</td>
                            <td class="tg-9wq8">0.00%</td>
                            <td class="tg-9wq8">28.2%</td>
                          </tr>
                          <tr>
                            <td class="tg-9wq8">2874-138-033585/03-00104-0123</td>
                            <td class="tg-9wq8">Crédito Hipotecario ARTPARK</td>
                            <td class="tg-9wq8">Comisión por apertura de línea de crédito</td>
                            <td class="tg-9wq8">3 %</td>
                            <td class="tg-9wq8">Costo en función al monto del crédito</td>
                            <td class="tg-9wq8">18.00%</td>
                            <td class="tg-9wq8">0.00%</td>
                            <td class="tg-9wq8">27.8%</td>
                          </tr>
                          <tr>
                            <td class="tg-9wq8">2874-140-027459/05-00174-0123</td>
                            <td class="tg-9wq8">Préstamo Personal</td>
                            <td class="tg-9wq8">Comisión por contratación o apertura de línea de crédito</td>
                            <td class="tg-9wq8">5 %</td>
                            <td class="tg-9wq8">Sobre monto o línea de crédito por única vez</td>
                            <td class="tg-9wq8">150.00%</td>
                            <td class="tg-9wq8">0.00%</td>
                            <td class="tg-9wq8">328.5%</td>
                          </tr>
                          <tr>
                            <td class="tg-9wq8">2874-439-026962/05-00175-0123</td>
                            <td class="tg-9wq8">Préstamo Empresarial</td>
                            <td class="tg-9wq8">El producto no prevé comisión alguna</td>
                            <td class="tg-9wq8">-</td>
                            <td class="tg-9wq8">-</td>
                            <td class="tg-9wq8">10.00%</td>
                            <td class="tg-9wq8">0.00%</td>
                            <td class="tg-9wq8">12.2%</td>
                          </tr>
                          <tr>
                            <td class="tg-c3ow" colspan="8"></td>
                          </tr>
                          <tr>
                            <td class="tg-9wq8" colspan="8">Para más consultas puede ingresar a: www.condusef.gob.mx</td>
                          </tr>
                          <tr>
                            <td class="tg-9wq8" colspan="2">Teléfono de la SOFOM:</td>
                            <td class="tg-9wq8" colspan="6">33 3616 8059</td>
                          </tr>
                          <tr>
                            <td class="tg-9wq8" colspan="2">Página web de la SOFOM:</td>
                            <td class="tg-9wq8" colspan="6"><a href="https://percapita.mx/" target="_blank" rel="noopener noreferrer">https://percapita.mx/</a></td>
                          </tr>
                          <tr>
                            <td class="tg-c3ow" colspan="8"></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </motion.div>
  );
};
