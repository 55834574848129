import "../../../front/popup/popup.css";
import Popup from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import React, { useEffect, useState } from "react";
import AlertMessage from "../../../alert.component";
import logo_balnco from "../../../../img/logo_blanco.png";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function AlertAccount({
  setShowAlert
}) {
  const [size, setSize] = useState(0)
  const [ width, setWidth] = useState("50%")
  const [ height, setHeight] = useState("30%")
  useEffect(() => {
    const handleResize = () => {
      // Perform actions on window resize      
      setWidth(screen(window.innerWidth)); 
      setHeight(screenH(window.innerWidth));
      console.log("resize", window.innerWidth)
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {    
      setWidth(screen(window.innerWidth)); 
      setHeight(screenH(window.innerWidth));    
  }, []);

  function screen(width: any) {
    let height = "";
   if( width > 300 && width <= 700){    
    height = "85%";
   }else if( width > 700 && width <= 1200) {    
    height = "50%";
   }
    return height;
  }
  function screenH(width: any) {
    let widthN = "";
   if( width > 300 && width <= 700){    
    widthN = "30%";
   }else if( width > 700 ) {    
    widthN = "30%";
   }
    return widthN;
  }

  const aceptar = () => {
    setShowAlert(false)
  }
  return (
    <div>
      <AlertMessage
        open={false}
        message={"fd"}
        severity={"error"}
      />
      <Popup
        visible={true}
        closeOnOutsideClick={false}
         width={width}
         height={height}
        showTitle={false}
        position={{
          at: 'center',
           my: 'center',
        collision: 'none',
        }}   
        onHiding={aceptar}
      >
        <div>
          
        </div>
        <ScrollView width="100%" height="90%">
          <div id="textBlock">

            <h3 className="sol1-tittle text-center">
            Te recordamos que tu título funerario queda como garantía durante el plazo del crédito. 
            </h3>
            <div className="row">
              <div className="d-flex justify-content-around mt-3">
                <button
                  type="submit"
                  className="btn btn-shadow"
                  onClick={aceptar}
                >
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        </ScrollView>
      </Popup>
    </div>
  );
}