import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";
import ApiClient from "../../../services/apiclient";
import { nextStep } from "../../../store/actions/PrestamosPersonalesActions";
import Footer from "../../front/FooterI";
import esfera_logo from "../../../img/esfera_percapita.png";
import esfera_user from "../../../img/esfera_contact.png";
import flechai from "../../../img/flechai.png";
import flechad from "../../../img/flechaD.png";
import { motion } from "framer-motion";
import Navbar from "../../front/NavbarI";
import { PreTramiteCreditoContext } from "../../../context/PreTramiteCreditoProvider";
import {validateString} from "../../../utils/StringValidator"
import logo_balnco from "../../../img/logo_blanco.png";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const ReferencesForm = ({ nominaId, nextStep }) => {
  const apiClient = ApiClient.getInstance();
  const history = useHistory();
  const [amount, setAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { preCredito, updatePreCredito, deletePreCredito } = React.useContext(
    PreTramiteCreditoContext
  );
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();
  const [preInfo, setPreInfo] = useState(null);

  const onSubmit = (data) => {
    const personalReferences = [
      {
        name: data?.nameRef1,
        relationship: data?.relationShip1,
        contactNumber: data?.phoneRef1,
      },
      {
        name: data?.nameRef2,
        relationship: data?.relationShip2,
        contactNumber: data?.phoneRef2,
      },
    ];

    const body = { personalReferences };

    setIsLoading(true);

    apiClient
      .referencesFormDiremovilPromotion(body, nominaId)
      .then((data) => {
        if (localStorage.getItem("preCreditoLocal")) {
          var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
          if (
            credito.idProcessMarketing !== "" &&
            credito.typeLoanMarketing === "Personal"
          ) {
            var dd = {
              processName: "personal-reference-finish",
            };
            updatePreCredito({
              ...preCredito,
              stepMarketing: "personal-reference-finish",
            });
            updateMarketing(credito.idProcessMarketing, dd);
          }
        }
        nextStep("QUESTION-PEPS");
        console.log(data);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  // Redirect to /Veriff if user is not verified
  useEffect(() => {
    const checkUserVeriffValidated = async () => {
      const isValidatedRes = await apiClient.checkUserVeriffValidated();
      if (isValidatedRes.veriffValidated !== true) {
        history.push("/veriff?from=prestamo-personal");
        return;
      }
    };
    const timeout = setTimeout(() => {
      checkUserVeriffValidated();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    apiClient.getUserPreInfo().then((data) => {
      if (!data.error) {
        const userPreInfo = data;
        setPreInfo(userPreInfo);
        reset(userPreInfo);
      }
    });
  }, []);
  const checkActiveCredit = async () => {
    const activeCredit = await apiClient.getActiveCredit();
    const credit = await apiClient.getCredit(activeCredit.creditType);
    setAmount(credit.amount);
    return activeCredit;
  };
  useEffect(() => {
    checkActiveCredit();
  }, []);

  const updateMarketing = async (id: any, data: any) => {
    await apiClient.updateMarketingTrack(id, data);
  };

  useEffect(() => {
    if (localStorage.getItem("preCreditoLocal")) {
      var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
      if (
        credito.stepMarketing != "personal-reference-init" &&
        credito.typeLoanMarketing === "Personal"
      )
        var data = {
          processName: "personal-reference-init",
        };
      updatePreCredito({
        ...preCredito,
        stepMarketing: "personal-reference-init",
      });
      updateMarketing(credito.idProcessMarketing, data);
    }
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.6 }}
      className="grey-background"
    >
      <Navbar />
      <br />
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-block d-sm-block d-md-none p-0 m-0 mb-5"
      >
        <div className="card card-monto">
          <div className="container">
            <h6 className="text-credit text-end p-2">Monto solicitado:</h6>
            <h2 className="text-light-blue text-end">
              {" "}
              {amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h2>
          </div>
        </div>
      </motion.div>
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="row"
      >
        <div className="col-3">
          <div className="d-none d-sm-none d-md-block p-0 m-0 ">
            <div className="card card-monto">
              <div className="container">
                <h6 className="text-credit text-end p-2">Monto solicitado:</h6>
                <h2 className="text-light-blue text-end">
                  {" "}
                  {amount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="text-center">
            <h3 className="sol1-tittle">Solicitud de préstamo</h3>
            <p>INFORMACIÓN EXTRA</p>
          </div>
        </div>
        <div className="col-3"></div>
      </motion.div>
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-flex justify-content-center"
      >
        <img src={esfera_logo} alt="PRADO'S" className="img-fluid" width="60" />
      </motion.div>
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="text-center mt-3    "
      >
        <h6 className="txt-blue-strong2">REFERENCIAS PERSONALES</h6>
      </motion.div>
      {/* STEPS */}
      {/* CARDS */}
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-flex justify-content-center mt-4 container"
      >
        <div className="card p-4 card-redounded-lg shadow p-3 mb-5 bg-body">
          <div className="container">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="d-flex justify-content-start">
                <img
                  src={esfera_user}
                  alt="PRADO'S"
                  className="img-fluid"
                  width="52"
                />
                <p className="txt-blue-strong2 text-contact">
                  REFERENCIA PERSONAL 01
                </p>
              </div>
              <br />
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <div className="mb-3">
                    <label
                      htmlFor="nameRef1"
                      className="form-label sol1-tittle"
                    >
                      Nombre completo
                    </label>
                    <input
                      type="text"
                      className={
                        errors.nameRef1
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      id="nameRef1"
                      name="nameRef1"
                      {...register("nameRef1", { required: true })}
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setValue(
                          "nameRef1",
                          validateString(value.toUpperCase())
                        );
                      }}
                    />
                    {errors.nameRef1 && (
                      <div className="invalid-feedback">
                        Nombre referencia es requerido
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <div className="mb-3">
                    <label
                      htmlFor="relationShip1"
                      className="form-label sol1-tittle"
                    >
                      Parentesco*
                    </label>
                    <select
                      aria-label="Default select example"
                      name="relationShip1"
                      id="relationShip1"
                      {...register("relationShip1", { required: true })}
                      className={
                        errors.relationShip1
                          ? " form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                    >
                      <option selected disabled value="">
                        Selecciona una opción
                      </option>
                      <option value="Familiar">Familiar</option>
                      <option value="Amistad">Amistad</option>
                    </select>
                    {errors.relationShip1 && (
                      <div className="invalid-feedback">
                        Parentesco es requerido
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <div className="mb-3">
                    <label
                      htmlFor="phoneRef1"
                      className="form-label sol1-tittle"
                    >
                      Teléfono de contacto
                    </label>
                    <input
                      type="text"
                      className={
                        errors.phoneRef1
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      id="phoneRef1"
                      aria-describedby="emailHelp"
                      {...register("phoneRef1", {
                        required: true,
                        pattern: /[0-9]+/,
                      })}
                      name="phoneRef1"
                    />
                    {errors.phoneRef2 && (
                      <div className="invalid-feedback">
                        Telefono referencia es requerido
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-start">
                <img
                  src={esfera_user}
                  alt="PRADO'S"
                  className="img-fluid"
                  width="52"
                />
                <p className="txt-blue-strong2 text-contact">
                  REFERENCIA PERSONAL 02
                </p>
              </div>
              <br />
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <div className="mb-3">
                    <label
                      htmlFor="nameRef2"
                      className="form-label sol1-tittle"
                    >
                      Nombre completo
                    </label>
                    <input
                      type="text"
                      className={
                        errors.nameRef2
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      id="nameRef2"
                      name="nameRef2"
                      {...register("nameRef2")}
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setValue(
                          "nameRef2",
                          validateString(value.toUpperCase())
                        );
                      }}
                    />
                    {errors.nameRef2 && (
                      <div className="invalid-feedback">
                        Nombre referencia es requerido
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <div className="mb-3">
                    <label
                      htmlFor="relationShip2"
                      className="form-label sol1-tittle"
                    >
                      Parentesco
                    </label>
                    <select
                      aria-label="Default select example"
                      {...register("relationShip2", { required: true })}
                      className={
                        errors.relationShip2
                          ? " form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                    >
                      <option selected disabled value="">
                        Selecciona una opción
                      </option>
                      <option value="Familiar">Familiar</option>
                      <option value="Amistad">Amistad</option>
                    </select>
                    {errors.relationShip2 && (
                      <div className="invalid-feedback">
                        Parentesco es requerido
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <div className="mb-3">
                    <label
                      htmlFor="phoneRef2"
                      className="form-label sol1-tittle"
                    >
                      Teléfono de contacto
                    </label>
                    <input
                      type="text"
                      className={
                        errors.phoneRef2
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      id="phoneRef2"
                      aria-describedby="emailHelp"
                      {...register("phoneRef2", {
                        required: true,
                        pattern: /[0-9]+/,
                      })}
                      name="phoneRef2"
                    />
                    {errors.phoneRef2 && (
                      <div className="invalid-feedback">
                        Telefono referencia es requerido
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <hr className="mt-4 mb-4" />
              <div className="row">
                {/* <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="d-flex justify-content-center mt-5 mb-4">
                                        <a href='/solicitud/personal/10' className="btn btn-shadow-2">
                                            <img src={flechai} alt="PRADO'S" className="img-fluid mrirht" width="10" />
                                            Regresar
                                        </a>
                                    </div>
                                </div> */}
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="d-flex justify-content-center mt-5 mb-4">
                    <button className="btn btn-shadow-2" type="submit">
                      Continuar
                      <img
                        src={flechad}
                        alt="PRADO'S"
                        className="img-fluid mleft"
                        width="10"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </motion.div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.appBar + 1 }}
        open={isLoading}
      >
        <img
          src={logo_balnco}
          alt="PRADO'S"
          className="img-fluid p-3 img-client"
          width="300"
        />
        <CircularProgress color="primary" size={80} />
      </Backdrop>
      <Footer />
    </motion.div>
  );
};

const mapStateToProps = ({ personalCreditReducer }) => ({
  user: personalCreditReducer?.user ?? {},
  nominaId: personalCreditReducer?.nominaId ?? "",
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      nextStep,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferencesForm);
