export function validateString(cadena: string): string {

    // Utilizar Unicode escape sequences para los caracteres con acentos
    const regex: RegExp = /[áéíóúüñÁÉÍÓÚÜ]/g;

    // Crear una función de reemplazo para mapear los caracteres a sus equivalentes sin acentos
    const reemplazarAcento = (match: string): string => {
        switch (match) {
            case 'á': return 'a';
            case 'é': return 'e';
            case 'í': return 'i';
            case 'ó': return 'o';
            case 'ú': return 'u';
            case 'ü': return 'u';
            case 'Á': return 'A';
            case 'É': return 'E';
            case 'Í': return 'I';
            case 'Ó': return 'O';
            case 'Ú': return 'U';
            case 'Ü': return 'U';

            case 'Ä': return 'A';
            case 'Ë': return 'E';
            case 'Ï': return 'I';
            case 'Ö': return 'O';
            case 'Ü': return 'U';
            case 'ä': return 'a';
            case 'ë': return 'e';
            case 'ï': return 'i';
            case 'ö': return 'o';
            case 'ü': return 'u';
            case ' ': return '';
        
            // case 'Ó': return 'O';
            // case 'Ú': return 'U';
            // case 'Ü': return 'U';¨

            default: return match;
        }
    };

    // Aplicar la función de reemplazo a la cadena
    const cadenaSinAcentos: string = cadena.replace(regex, reemplazarAcento);

    return cadenaSinAcentos;
}