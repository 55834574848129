import React from 'react'
import '../../../../css/style.css'
import foto_perfil from '../../../../img/foto-perfil.png';
import { nextNav } from "../../../../store/actions/navActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { UserContext } from "../../../../context/UserProvider";
import moment from "moment";
import 'moment/locale/es'
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { TramiteCreditoContext } from "./../../../../context/TramiteCreditoProvider";
import {  useHistory } from "react-router-dom";



const NavDashboard = ({
    nextNav
}) => {
    const { userMain, updateUser } = React.useContext(UserContext);
    const { credito, updateCredito } = React.useContext(TramiteCreditoContext);
const history = useHistory();
    const activa =  (numero : number) => {
        nextNav(numero);
      }
      function stringToColor(string: string) {
        let hash = 0;
        let i;
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
      }
      
      function stringAvatar(name: string) {
        return {
          sx: {
            background: 'radial-gradient(circle at top left, #3FE5ED, #005593);'
          },
          children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
      }

      function handleLogout(e: { preventDefault: () => void }) {
        e.preventDefault();
        updateCredito({
          idPrestamo: '',
          fecha_solicitud: '',
          isApproved: false,
          isDelivered: false,
          estatusSolicitud: 'sin-credito',
          importe: 0,
          paymentStatus: '0', // (0 = nuevo, 1 = en proceso, 2 = aprobado, 3 = Rechazado)
          importe_interes: 0,
          total_importe: 0,
          numero_plazo: 0,
          importe_liquidar: 0,
          importe_pendiente: 0,
          detalles: [],
          limitAmountCredit: 20000, // limite de dinero a prestar
          limitTermCredit: 12, // limite de meses
          tasa: 70,
        });
        localStorage.clear();
        updateUser({ ...userMain, nombre: 'Iniciar Sesión', apellidoPaterno: '' });
        return history.push('/login');
      }
      
    return (
            <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block sidebar collapse p-0 m-0" >
                        <div className="card card-slider p-0 m-0 ">
                            <div className="p-3 d-flex justify-content-center">
                            <Stack direction="row" spacing={3}>
                                <Avatar {...stringAvatar(userMain.nombre)}
                               />
                                </Stack>
                                {/* <img src={foto_perfil} alt="PRADO'S" className="img-fluid" width="150" /> */}
                            </div>
                            <div className="text-center container mb-4">
                                <h5 className="text-strong-blue">{userMain.nombre}</h5>
                                <small className="text-strong-blue ">{moment().format("LL")}</small>
                            </div>
                        </div>
                        <div className="bg-blue-slider position-sticky pt-3 container">
                            <ul className="nav flex-column ">
                                <li className="nav-item active" id="0" >
                                    <a className="nav-link text-white active" aria-current="page" onClick={()=>activa(0)} href="#">
                                        <h6 id="0">Inicio</h6>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" aria-current="page" id="1" onClick={()=>activa(1)} href="#">
                                        <h6>Crédito actual</h6>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" aria-current="page" id="2" onClick={()=>activa(2)} href="#">
                                        <h6>Estado de cuenta</h6>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" aria-current="page" id="3" onClick={()=>activa(3)} href="#">
                                        <h6>Mis datos</h6>
                                    </a>
                                </li>
                                <li className="nav-item mb-5">
                                    <a className="nav-link text-white" aria-current="page" id="4" onClick={handleLogout} href="#">
                                        <h6>Cerrar sesión</h6>
                                    </a>
                                </li>
                            </ul>

                        </div>
                    </nav>
    )
}
const mapStateToProps = ({ navReducer }) => ({
    user: navReducer?.user ?? {},
    nominaId: navReducer?.nominaId ?? "",
  });
  
  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
      nextNav,
    }, dispatch);
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(NavDashboard);