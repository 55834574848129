import React from 'react'
import logo_balnco from '../../../img/logo_blanco.png';
import fondo_1 from '../../../img/fondo_1.png';
import fondo_2 from '../../../img/fondo_2.png';
import Navbar from '../../front/NavbarI';
import Footer from '../../front/FooterI';
import paso_1 from '../../../img/paso_1.png';
import paso_2 from '../../../img/paso_2.png';
import paso_3 from '../../../img/paso_3.png';
import paso_4 from '../../../img/paso_4.png';

export default function Solicitud_8()  {
    return (
        <div className="blue-background-mess">
            <Navbar />
            <div className="d-none d-sm-none d-md-block p-0 m-0 ">
                <div className="row">
                    <div className="col-2">
                        <img src={fondo_2} alt="PRADO'S" className="img-fluid img-top-sol8" width="250" />
                    </div>
                    <div className="col-8">
                        <div className="d-flex justify-content-center mb-1">
                            <img src={logo_balnco} alt="PRADO'S" className="img-fluid p-2 img-client mt-5 mb-4" width="240" />
                        </div>
                        <div className="text-white text-center">
                            <h1 className="mt-2 text-tittle">¡Felicidades!</h1>
                            <h4 className="mt-3">Tu crédito ya está Pre-autorizado <br />
                                hasta <span className="text-blue-light">$8,0000</span></h4>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                <div className="mb-3">
                                    <img src={paso_1} alt="PRADO'S" className="img-fluid p-2 img-client" width="50" />
                                    <label htmlFor="exampleInputEmail1" className="form-label white-tittle">Elige el monto de tu credito *</label>
                                    <select className="form-select" aria-label="Default select example">
                                        <option selected>Seleccione una opcion</option>
                                    </select>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                        <img src={paso_2} alt="PRADO'S" className="img-fluid p-2 img-client" width="50" />
                                        <label htmlFor="exampleInputEmail1" className="form-label white-tittle">Plazo *</label>
                                        <select className="form-select" aria-label="Default select example">
                                            <option selected>Plazo</option>
                                        </select>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                        <img src={paso_3} alt="PRADO'S" className="img-fluid p-2 img-client" width="50" />
                                        <label htmlFor="exampleInputEmail1" className="form-label white-tittle">Duracion *</label>
                                        <select className="form-select" aria-label="Default select example">
                                            <option selected>Duración</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-2 text-center text-white">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 p-4">
                                        <h4 className="mt-2">Pagos <br /> en</h4>
                                        <h5>6 meses</h5>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 p-4">
                                        <h4 className="mt-2">Tasa <br /> promedio</h4>
                                        <h5>42.93%</h5>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 p-4">
                                        <h4 className="mt-2">Tu <br /> pago</h4>
                                        <h5>$2450</h5>
                                        <small>mensual</small>
                                    </div>
                                </div>

                                <div className="text-start text-white d-flex mt-3">
                                    <img src={paso_4} alt="PRADO'S" className="img-fluid p-2 img-client" width="50" />
                                    <label className="">Por último necesitamos validar tu identidad</label>
                                </div>

                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-2 mb-4">
                            <a href='/solicitud/personal/9' className="btn btn-continue-large mt-5">
                                Comenzar validación
                            </a>
                        </div>

                    </div>
                    <div className="col-2">
                        <img src={fondo_1} alt="PRADO'S" className="img-fluid img-top2-sol8" width="260" />
                    </div>
                </div>
            </div>
            <div className="d-block d-sm-block d-md-none p-0 m-0 ">
                <div className="d-flex justify-content-center mb-1">
                    <img src={logo_balnco} alt="PRADO'S" className="img-fluid p-2 img-client mt-5 mb-4" width="240" />
                </div>
                <div className="text-white text-center container">
                    <h1 className="mt-1 text-tittle">¡Felicidades!</h1>
                    <h4 className="mt-2">Tu crédito ya está Pre-autorizado <br />
                        hasta <span className="text-blue-light">$8,000</span></h4>
                    <div className="mb-3">

                        <label htmlFor="exampleInputEmail1" className="form-label white-tittle">Elige el monto de tu credito*</label>
                        <select className="form-select" aria-label="Default select example">
                            <option selected>Seleccione una opcion</option>
                        </select>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <select className="form-select mb-3" aria-label="Default select example">
                                <option selected>Plazo</option>
                            </select>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <select className="form-select" aria-label="Default select example">
                                <option selected>Duración</option>
                            </select>
                        </div>
                    </div>
                    <hr />
                    <h4 className="mt-2">Pagos en</h4>
                    <h5>6 meses</h5>
                    <hr />
                    <h4 className="mt-2">Tasa promedio</h4>
                    <h5>42.93%</h5>
                    <hr />
                    <h4 className="mt-2">Tu pago</h4>
                    <h5>$2450</h5>
                    <small>mensual</small>
                    <hr />
                    <div className="d-flex justify-content-center mt-2 mb-4">
                        <a href='/solicitud/personal/9' className="btn btn-continue mt-5 mb-5">
                            Continuar
                        </a>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
