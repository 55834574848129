import React from 'react'
import '../../../../css/style.css'
import logo from '../../../../img/logo_blanco.png';
import icono_user from '../../../../img/icnos-user-blanco.png';
import icono_whats from '../../../../img/icnos-whatsblanco.png';
import icon_home from '../../../../img/home-icon.png';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { UserContext } from "../../../../context/UserProvider";
import { TramiteCreditoContext } from "./../../../../context/TramiteCreditoProvider";
import {  useHistory } from "react-router-dom";

export default function HeaderDashboard() {
    const { credito, updateCredito } = React.useContext(TramiteCreditoContext);
    const history = useHistory();
    const { userMain, updateUser } = React.useContext(UserContext);
    function stringToColor(string: string) {
        let hash = 0;
        let i;
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
      }
      
      function stringAvatar(name: string) {
        return {
          sx: {
            bgcolor: stringToColor(name),
          },
          children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
      }

      function handleLogout(e: { preventDefault: () => void }) {
        e.preventDefault();
        updateCredito({
          idPrestamo: '',
          fecha_solicitud: '',
          isApproved: false,
          isDelivered: false,
          estatusSolicitud: 'sin-credito',
          importe: 0,
          paymentStatus: '0', // (0 = nuevo, 1 = en proceso, 2 = aprobado, 3 = Rechazado)
          importe_interes: 0,
          total_importe: 0,
          numero_plazo: 0,
          importe_liquidar: 0,
          importe_pendiente: 0,
          detalles: [],
          limitAmountCredit: 20000, // limite de dinero a prestar
          limitTermCredit: 12, // limite de meses
          tasa: 70,
        });
        localStorage.clear();
        updateUser({ ...userMain, nombre: 'Iniciar Sesión', apellidoPaterno: '' });
        return history.push('/login');
      }
      
    return (
        <div>
            <div className="d-none d-sm-none d-md-block p-0 m-0 ">
                <header className="navbar sticky-top flex-md-nowrap p-0 shadow nav-cuenta">
                    <a className="navbar-brand navbar-brand-left p-3" href="/">
                        <img src={logo} alt="PRADO'S" className="img-fluid" width="170" />
                    </a>
                    <br></br>
                    <button className="navbar-toggler d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="navbar-nav ">
                        <div className="nav-item text-nowrap d-flex justify-content-end">
                            <a className="nav-link nav-text-cuenta" href="#"> <h6 className="text-nav-cuenta">Sobre nosotros</h6></a>
                            <a className="nav-link nav-text-cuenta" href="#"> <h6 className="text-nav-cuenta">Financiamiento</h6></a>
                            <a className="nav-link nav-text-cuenta" href="#"> <h6 className="text-nav-cuenta">Crédito PABS</h6></a>
                            <a className="nav-link nav-text-cuenta" href="#"> <h6 className="text-nav-cuenta">Blog</h6></a>
                            <a className="nav-link nav-text-cuenta" href="#"> <h6 className="text-nav-cuenta">
                       
                                <Avatar {...stringAvatar(userMain.nombre)}/>
                                </h6></a>
                            <a className="nav-link nav-text-cuenta" href="#"> <h6 className="text-nav-cuenta"><img src={icono_whats} alt="PRADO'S" className="img-fluid" /></h6></a>
                            <a className="nav-link nav-text-cuenta" href="#" onClick={handleLogout}> <h6 className="text-nav-cuenta">Cerrar sesión</h6></a>
                        </div>

                    </div>
                </header>
            </div>
            <div className="d-block d-sm-block d-md-none p-0 m-0 ">
                <nav className="navbar navbar-light navbar-expand-lg nav-cuenta p-0 m-0 ">
                    <div className="container-fluid p-0 m-0 ">
                        <a className="navbar-brand p-2" href="#"><img src={logo} alt="PRADO'S" className="img-fluid" width="170" /></a>
                        <a className="nav-image-movil" href="#"><Avatar {...stringAvatar(userMain.nombre)}/></a>
                        <a className="nav-image-movil" href="#"><img src={icono_whats} alt="PRADO'S" className="img-fluid" /></a>
                        <a className="nav-image-movil" href="/"><img src={icon_home} alt="PRADO'S" className="img-fluid" /></a>
                        <button className="navbar-toggler navbar-toggler-cuenta d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <a className="nav-link nav-text" href=""> <h6 className="text-nav">Sobre nosotros</h6></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link nav-text" href="#"> <h6 className="text-nav">Financiamiento</h6></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link nav-text" href="#"> <h6 className="text-nav">Crédito PABS</h6></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link nav-text" href="#"> <h6 className="text-nav">Blog</h6></a>
                                </li>
                                <li className="nav-item nav-credit-movil">
                                    <a className="nav-link nav-text " href="#"> <h6 className="text-credit">Solicitar crédito</h6></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}
