import React from 'react'
import Navbar from '../../front/NavbarI';
import Footer from '../../front/FooterI';
import esfera_logo from '../../../img/esfera_percapita.png';
import flechai from '../../../img/flechai.png';
import flechad from '../../../img/flechaD.png';

export default function Solicitud_12()  {
    return (
        <div className="grey-background">
            <Navbar />
            <br />
            <br />
            <div className="d-block d-sm-block d-md-none p-0 m-0 mb-5">
                <div className="card card-monto">
                    <div className="container">
                        <h6 className="text-credit text-end p-2">
                            Monto solicitado:
                        </h6>
                        <h2 className="text-light-blue text-end"> $1,200</h2>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <div className="d-none d-sm-none d-md-block p-0 m-0 ">
                        <div className="card card-monto">
                            <div className="container">
                                <h6 className="text-credit text-end p-2">
                                    Monto solicitado:
                                </h6>
                                <h2 className="text-light-blue text-end"> $1,200</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="text-center">
                        <h3 className="sol1-tittle">Solicitud de préstamo</h3>
                        <p>Información extra</p>
                    </div>
                </div>
                <div className="col-3"></div>
            </div>
            {/* STEPS */}
            <div className="d-flex justify-content-center">
                <img src={esfera_logo} alt="PRADO'S" className="img-fluid" width="60" />
            </div>
            <div className="text-center mt-3    ">
                <h6 className="txt-blue-strong2">SUBE TUS DOCUMENTOS </h6>
            </div>
            {/* CARDS */}
            <div className="d-flex justify-content-center mt-4 container">
                <div className="card p-4 card-redounded-lg shadow p-3 mb-5 bg-body">
                    <div className="container">
                        <form>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-2"></div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label sol1-tittle">Sube la foto de tu <br />
                                            comprobante de domicilio</label>
                                        <div className="fileupload fileupload-new" data-provides="fileupload">
                                            <span className="btn btn-file btn-file"><span className="fileupload-new">Seleccione un archivo</span>
                                                <span className="fileupload-exists">Cambiar</span>         <input type="file" /></span>
                                            <span className="fileupload-preview"></span>
                                            <a href="#" className="close fileupload-exists" data-dismiss="fileupload" >×</a>
                                        </div>
                                        <p>El domicilio del comprobante <br /> debe ser igual a la dirección que pusiste previamente.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label sol1-tittle">Sube la foto de tu <br />
                                            comprobante de ingresos</label>
                                        <div className="fileupload fileupload-new" data-provides="fileupload">
                                            <span className="btn btn-file btn-file"><span className="fileupload-new">Seleccione un archivo</span>
                                                <span className="fileupload-exists">Cambiar</span> <input type="file" /></span>
                                            <span className="fileupload-preview"></span>
                                            <a href="#" className="close fileupload-exists" data-dismiss="fileupload" >×</a>
                                        </div>
                                        <p>Tu comprobante de ingresos debe <br /> reflejar correctamente tus ingresos <br /> mensuales.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-2"></div>
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                                <div className="form-check">
                                    <input className="form-check-input checkbox-terms" type="checkbox" value="" id="flexCheckDefault" />
                                    <label className="form-check-label text-check" htmlFor="flexCheckDefault">
                                        Certifico que los datos proporcionados son verídicos y que actúo en nombre propio al solicitar este crédito
                                    </label>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-1"></div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-10">
                                    <div className="mb-3">
                                        <p>Probenso Group, Sociedad Anónima, Sociedad Financiera de Objeto Multipe, Entidad No Regulada (E.N.R), con domicilio en Ures Sur 137, Colonia Centro, Tepic, 63000, Nayarit. México es el responsable de sus datos personales. Utilizaremos tus datos personales para los siguientes indispensables.
                                        </p>
                                        <br />
                                        <p>(i) Crear tu cuenta de usuario en la plataforma alojada en httos://percapita.mx (ii) Identificarte como usuario de la plataforma alojada en  httos://percapita.mx (iii) Generar el expediente de solicitud de tu crédito (iv) Consultar tu historial y capacidad crediticia; (v) Decidir si se aprueba o rechaza tu solicitud de crédito, y (vi) Firmar los contratos de crédito que sean aprobados. Para conocer el Aviso de Privacidad integral, por favor da click aquí.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-1"></div>
                            </div>
                            <hr className="mt-4 mb-4" />
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="d-flex justify-content-center mt-5 mb-4">
                                        <a href='/solicitud/personal/11' className="btn btn-shadow-2">
                                            <img src={flechai} alt="PRADO'S" className="img-fluid mrirht" width="10" />
                                            Regresar
                                        </a>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="d-flex justify-content-center mt-5 mb-4">
                                        <a href='/solicitud/personal/13' className="btn btn-shadow-2">
                                            Continuar
                                            <img src={flechad} alt="PRADO'S" className="img-fluid mleft" width="10" />
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
