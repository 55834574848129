import React from 'react'
import  Footer  from '../../front/FooterI'
import  Navbar  from '../../front/NavbarI'
import icon_percapita from '../../../img/icon-percapita.png';
import educacion_1 from '../../../img/educacion-1.png';
import icono_calendario from '../../../img/icono-calendario.png';
import finanzas_2 from '../../../img/finanzas-2.png';
import finanzas_6 from '../../../img/finanzas-6.png';
import finanzas_4 from '../../../img/finanzas-4.png';
import educacion_2 from '../../../img/educacion-2.png';
import finanzas_3 from '../../../img/finanzas-3.png';
import finanzas_1 from '../../../img/finanzas-1.png';
import {motion} from "framer-motion";

export default function Blog() {
    return (
        <motion.div
        initial={{  opacity:0 }}
          animate={{opacity:1 }}
          exit={{opacity:0}}
          transition={{ ease: "easeInOut", duration: 0.6 }}>
            <Navbar />
            <div class="bg-blog">
                <div class="container">
                    <div class="row pt-3">
                        <div class="text-center pt-5">
                            <img src={icon_percapita} alt="PERCAPITA" class="img-fluid  icono-headers" />
                            <h1 class="rBlack display-4 mt-0">Blog</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container py-5">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-2">
                        <div class="text-start">
                            <select class="form-select text-center" aria-label="Default select example">
                                <option selected>Ordenar por</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8"></div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-2">
                        <div class="text-end">
                            <select class="form-select text-center" aria-label="Default select example">
                                <option selected>Categorías</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row py-4">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 mb-4">
                        <div class="entrada-blog">
                            <img src={educacion_1} alt="PERCAPITA" class="img-fluid" />
                            <div class="cuadro-blog py-2">
                                <div class="text-center">
                                    <h6 class="rLight pt-1">Educación</h6>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3 fecha-pub">
                            <h6 class="oRegular">
                                <img src={icono_calendario} alt="PERCAPITA" class="img-fluid" />
                                agosto 15, 2022
                            </h6>
                        </div>
                        <div class="mt-2">
                            <h5 class="txt-azul-marino rExtraBold">Estrategias financieras para sobrevivir a una crisis económica</h5>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 mb-4">
                        <div class="entrada-blog">
                            <img src={finanzas_1} alt="PERCAPITA" class="img-fluid" />
                            <div class="cuadro-blog py-2">
                                <div class="text-center">
                                    <h6 class="rLight pt-1">Finanzas</h6>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3 fecha-pub">
                            <h6 class="oRegular">
                                <img src={icono_calendario} alt="PERCAPITA" class="img-fluid" />
                                agosto 15, 2022
                            </h6>
                        </div>
                        <div class="mt-2">
                            <h5 class="txt-azul-marino rExtraBold">Cómo enseñas educación financiera a los niños</h5>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 mb-4">
                        <div class="entrada-blog">
                            <img src={finanzas_2} alt="PERCAPITA" class="img-fluid" />
                            <div class="cuadro-blog py-2">
                                <div class="text-center">
                                    <h6 class="rLight pt-1">Finanzas</h6>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3 fecha-pub">
                            <h6 class="oRegular">
                                <img src={icono_calendario} alt="PERCAPITA" class="img-fluid" />
                                agosto 15, 2022
                            </h6>
                        </div>
                        <div class="mt-2">
                            <h5 class="txt-azul-marino rExtraBold">Cómo empezar a ahorrar para el retiro</h5>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 mb-4">
                        <div class="entrada-blog">
                            <img src={educacion_2} alt="PERCAPITA" class="img-fluid" />
                            <div class="cuadro-blog py-2">
                                <div class="text-center">
                                    <h6 class="rLight pt-1">Educación</h6>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3 fecha-pub">
                            <h6 class="oRegular">
                                <img src={icono_calendario} alt="PERCAPITA" class="img-fluid" />
                                agosto 15, 2022
                            </h6>
                        </div>
                        <div class="mt-2">
                            <h5 class="txt-azul-marino rExtraBold">¿Cómo financiar la compra de tu vivivenda, auto o emprendimiento?</h5>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 mb-4">
                        <div class="entrada-blog">
                            <img src={finanzas_3} alt="PERCAPITA" class="img-fluid" />
                            <div class="cuadro-blog py-2">
                                <div class="text-center">
                                    <h6 class="rLight pt-1">Finanzas</h6>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3 fecha-pub">
                            <h6 class="oRegular">
                                <img src={icono_calendario} alt="PERCAPITA" class="img-fluid" />
                                agosto 15, 2022
                            </h6>
                        </div>
                        <div class="mt-2">
                            <h5 class="txt-azul-marino rExtraBold">Señales de que tus finanzas están en riesgo</h5>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 mb-4">
                        <div class="entrada-blog">
                            <img src={finanzas_4} alt="PERCAPITA" class="img-fluid" />
                            <div class="cuadro-blog py-2">
                                <div class="text-center">
                                    <h6 class="rLight pt-1">Finanzas</h6>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3 fecha-pub">
                            <h6 class="oRegular">
                                <img src={icono_calendario} alt="PERCAPITA" class="img-fluid" />
                                agosto 15, 2022
                            </h6>
                        </div>
                        <div class="mt-2">
                            <h5 class="txt-azul-marino rExtraBold">¿Cómo costear la educación de tus hijos?</h5>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 mb-4">
                        <div class="entrada-blog">
                            <img src={educacion_2} alt="PERCAPITA" class="img-fluid" />
                            <div class="cuadro-blog py-2">
                                <div class="text-center">
                                    <h6 class="rLight pt-1">Educación</h6>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3 fecha-pub">
                            <h6 class="oRegular">
                                <img src={icono_calendario} alt="PERCAPITA" class="img-fluid" />
                                agosto 15, 2022
                            </h6>
                        </div>
                        <div class="mt-2">
                            <h5 class="txt-azul-marino rExtraBold">Qué son los gastos hormiga y cómo podemos reducirlos</h5>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 mb-4">
                        <div class="entrada-blog">
                            <img src={finanzas_6} alt="PERCAPITA" class="img-fluid" />
                            <div class="cuadro-blog py-2">
                                <div class="text-center">
                                    <h6 class="rLight pt-1">Finanzas</h6>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3 fecha-pub">
                            <h6 class="oRegular">
                                <img src={icono_calendario} alt="PERCAPITA" class="img-fluid" />
                                agosto 15, 2022
                            </h6>
                        </div>
                        <div class="mt-2">
                            <h5 class="txt-azul-marino rExtraBold">Por qué pedir un prestamo puede ser una buena opción</h5>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 mb-4">
                        <div class="entrada-blog">
                            <img src={finanzas_6} alt="PERCAPITA" class="img-fluid" />
                            <div class="cuadro-blog py-2">
                                <div class="text-center">
                                    <h6 class="rLight pt-1">Finanzas</h6>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3 fecha-pub">
                            <h6 class="oRegular">
                                <img src={icono_calendario} alt="PERCAPITA" class="img-fluid" />
                                agosto 15, 2022
                            </h6>
                        </div>
                        <div class="mt-2">
                            <h5 class="txt-azul-marino rExtraBold">Cómo enseñar educación financiera a los niños</h5>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 mb-4">
                        <div class="entrada-blog">
                            <img src={educacion_1} alt="PERCAPITA" class="img-fluid" />
                            <div class="cuadro-blog py-2">
                                <div class="text-center">
                                    <h6 class="rLight pt-1">Educación</h6>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3 fecha-pub">
                            <h6 class="oRegular">
                                <img src={icono_calendario} alt="PERCAPITA" class="img-fluid" />
                                agosto 15, 2022
                            </h6>
                        </div>
                        <div class="mt-2">
                            <h5 class="txt-azul-marino rExtraBold">Estrategias financieras para sobrevivir a una crisis económica</h5>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 mb-4">
                        <div class="entrada-blog">
                            <img src={finanzas_6} alt="PERCAPITA" class="img-fluid" />
                            <div class="cuadro-blog py-2">
                                <div class="text-center">
                                    <h6 class="rLight pt-1">Finanzas</h6>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3 fecha-pub">
                            <h6 class="oRegular">
                                <img src={icono_calendario} alt="PERCAPITA" class="img-fluid" />
                                agosto 15, 2022
                            </h6>
                        </div>
                        <div class="mt-2">
                            <h5 class="txt-azul-marino rExtraBold">Cómo enseñar educación financiera a los niños</h5>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 mb-4">
                        <div class="entrada-blog">
                            <img src={finanzas_2} alt="PERCAPITA" class="img-fluid" />
                            <div class="cuadro-blog py-2">
                                <div class="text-center">
                                    <h6 class="rLight pt-1">Educación</h6>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3 fecha-pub">
                            <h6 class="oRegular">
                                <img src={icono_calendario} alt="PERCAPITA" class="img-fluid" />
                                agosto 15, 2022
                            </h6>
                        </div>
                        <div class="mt-2">
                            <h5 class="txt-azul-marino rExtraBold">Cómo empezar a ahorrar para el retiro</h5>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="text-end">
                        <nav aria-label="...">
                            <ul class="pagination justify-content-end">
                                <li class="page-item active" aria-current="page">
                                    <span class="page-link">1</span>
                                </li>
                                <li class="page-item"><a class="page-link" href="#">2</a></li>
                                <li class="page-item"><a class="page-link" href="#">3</a></li>
                                <li class="page-item"><a class="page-link" href="#">4</a></li>
                                <li class="page-item"><a class="page-link" href="#">5</a></li>
                                <li class="page-item"><a class="page-link" href="#">6</a></li>
                                <li class="page-item"><a class="page-link" href="#">7</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <Footer />
        </motion.div>
    )
}
