import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Navbar from "./../../front/NavbarI";
import Footer from "./../../front/FooterI";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { PreTramiteCreditoContext } from "../../../context/PreTramiteCreditoProvider";
import { TramiteCreditoContext } from "../../../context/TramiteCreditoProvider";
import { UserContext } from "../../../context/UserProvider";
import logo_balnco from "../../../img/logo_blanco.png";
import ApiClient from "../../../services/apiclient";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import logo_pabs2 from "../../../img/logo_pabs.png";
import logo from "../../../img/logo-bco.png";
import icono_mujer from "../../../img/icono_mujer.png";
import { motion } from "framer-motion";
import { useAnimation, useInView } from "framer-motion";
import { CSSTransition, SwitchTransition } from "react-transition-group";

export default function Inicio() {
  const history = useHistory();
  const ref = useRef(null);
  const inView = useInView(ref);
  const [view, setView] = useState(false);
  const [param, setParam] = useState({
    minValue: 5000,
    maxValue: 11000,
    step: 3000,
  });
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "800px",

    maxHeight: "500px",
    background: "radial-gradient(circle at top left, #005593, #36c9d0)",
    boxShadow: 29,
    overflowY: "auto",
  };
  const [open2, setOpen2] = React.useState(false);
  const { preCredito, updatePreCredito, deletePreCredito } = React.useContext(
    PreTramiteCreditoContext
  );
  const { credito, updateCredito, deleteCredito } = React.useContext(
    TramiteCreditoContext
  );
  const { userMain, updateUser } = React.useContext(UserContext);
  const apiClient = ApiClient.getInstance();
  const [hasData, setHasData] = useState(false);
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState(0);
  const [amountText, setAmountText] = useState(amount);
  const [plazoL, setPlazoL] = useState("mensual");
  const [plazoM, setplazoM] = useState("12 meses");
  const [pago, setPago] = useState(840);
  const [fin, setFin] = useState(2000);
  const [hasDetails, setHasDetails] = useState(false);
  const animation = useAnimation();
  const animation2 = useAnimation();
  const animation3 = useAnimation();
  const animation4 = useAnimation();
  const [change, setChange] = useState(false);
  const nodeRef = useRef(null);
  const [change2, setChange2] = useState(false);
  const nodeRef2 = useRef(null);
  const [change3, setChange3] = useState(false);
  const nodeRef3 = useRef(null);
  const [change4, setChange4] = useState(false);
  const nodeRef4 = useRef(null);
  const query = useQuery();

  const [table, setTable] = useState([
    { monto: 5000, mensual: 930, plazo: "12 meses" },
    { monto: 8000, mensual: 1300, plazo: "12 meses" },
    { monto: 11000, mensual: 1400, plazo: "12 meses" },
  ]);
  function handleChange(event) {
    setChange(!change);
    setChange2(!change2);
    setChange3(!change3);
    setChange4(!change4);
    setAmount(parseFloat(event.target.value));
    setAmountText(parseFloat(event.target.value));
    const filter: any = table.filter(
      (e) => e.monto === parseFloat(event.target.value)
    );
     setPago(filter[0].mensual);
    setplazoM(filter[0].plazo);
  }
  function handleChangeRadio(event) {
    setPlazoL(event.target.value);
    const filter: any = table.filter((e) => e.monto === amount);
     setPago(filter[0].mensual)
    setplazoM(filter[0].plazo);
    setChange2(!change2);
    setChange4(!change4);
  }
  function next(event) {
    let limit = param.maxValue;
    let a = amount + 3000;
    if (a <= limit) {
      setAmountText(a);
      setAmount(a);
      setChange(!change);
      setChange2(!change2);
      setChange3(!change3);
      setChange4(!change4);
      const filter: any = table.filter((e) => e.monto === a);
       setPago(filter[0].mensual);
      setplazoM(filter[0].plazo);
    }
  }
  function previous(event) {
    let limit = param.minValue;
    let a = amount - 3000;
    if (a >= limit) {
      setAmountText(a);
      setAmount(a);
      setChange(!change);
      setChange2(!change2);
      setChange3(!change3);
      setChange4(!change4);
      const filter: any = table.filter((e) => e.monto === a);
       setPago(filter[0].mensual);
      setplazoM(filter[0].plazo);
    }
  }
  const params = useParams<any>();
  const updateMarketing = async (id: any, data: any) => {
    await apiClient.updateMarketingTrack(id, data);
  };
  const sendData = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (localStorage.getItem("preCreditoLocal")) {
      var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
      var data = {
        preAmount: amount,
        processName: "solicitud-monto-diremovil-finished",
      };

     // updateMarketing(credito.idProcessMarketing, data);
    }
    updateUser({
      ...userMain,
      preAmount: amount,
    });

    updatePreCredito({
      ...preCredito,
      amount: amount,
      paymentStatus: "0",
      term: plazoL,
      way_to_payment: plazoM,
      amount_payment: pago,
      step: "preSolicitud",
      stepMarketing: "solicitud-monto-diremovil-finished",
    });
    setOpen(true);
    setTimeout(async () => {
      setOpen(false);
      //const exist = await apiClient.userHasUserDetails();
        return history.push("/Diremovil");
    }, 1000);
  };
  const handleClose = () => {
    setOpen2(false);
  };

  const userDetails = async () => {
    const data: any = await apiClient.userHasUserDetails();

    setHasData(data);
  };

  useEffect(() => {
    if (inView) {
      animation3.start({
        opacity: 1,
        transition: { ease: "easeInOut", duration: 0.3 },
      });
      animation.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 0.8, delay: 0.3 },
      });
      animation2.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.2, delay: 0.3 },
      });
      animation4.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.6, delay: 0.3 },
      });
    }
    if (!inView) {
      animation3.start({
        opacity: 0,
      });
      animation.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation2.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation4.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
    }
  }, [inView]);
  useEffect(() => {
    setView(true);
  }, [view]);

  const setCredit = () => {
    setView(true);
    updateUser({
      ...userMain,
      creditType: 'diremovil',
    });
  };
  useEffect(() => {
    setCredit();
  }, []);

  //   useEffect(() => {
  //     const param = query.get("id");
  //     if (param) {
  //       alert("save data marketing");
  //     }
  //   }, []);
  const createTracking = async () => {
    const p = query.get("id");
    if (localStorage.getItem("preCreditoLocal")) {
      var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
      if (p && credito.idProcessMarketing === "") {
        const data = {
          typeLoan: params.type === "titulo" ? "Titulo" : "Personal",
          campaignName: p,
          processName: "solicitud-monto-init",
        };
        console.log(data);
        const a = await apiClient.saveMarketingTrack(data).then((r) => {
          updatePreCredito({
            ...preCredito,
            idProcessMarketing: r.id,
            stepMarketing: r.processName,
            typeLoanMarketing: params.type === "titulo" ? "Titulo" : "Personal",
          });
          console.log(r);
        });
      }
    } else {
      updatePreCredito({
        ...preCredito,
        amount: amount,
        paymentStatus: "0",
        term: plazoL,
        way_to_payment: plazoM,
        amount_payment: pago,
        step: "preSolicitud",
      });
      var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
      if (p && credito.idProcessMarketing === "") {
        const data = {
          typeLoan: params.type === "titulo" ? "Titulo" : "Personal",
          campaignName: p,
          processName: "solicitud-monto-init",
        };
        console.log(data);
        const a = await apiClient.saveMarketingTrack(data).then((r) => {
          updatePreCredito({
            ...preCredito,
            idProcessMarketing: r.id,
            stepMarketing: r.processName,
            typeLoanMarketing: params.type === "titulo" ? "Titulo" : "Personal",
          });
          console.log(r);
        });
      }
    }
  };
  useEffect(() => {
    //createTracking();
  }, []);
  return (
    <motion.div className="grey-background" animate={animation3} ref={ref}>
      <Navbar />
      <br />
      <br />
      <Modal
        keepMounted
        open={open2}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        {/* //<ScrollView width="100%" height="100%"> */}
        <Grid container spacing={2} sx={style}>
          <div className="modal-content">
            {/* <div className="d-flex justify-content-end p-1  ">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
            </div> */}
            <div className="modal-body">
              <div className="d-flex justify-content-center mb-3">
                <img
                  src={logo}
                  alt="PRADO'S"
                  width="220"
                  className="img-fluid"
                />
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="d-flex justify-content-center">
                    <img
                      src={logo_pabs2}
                      alt="PROGRAMA DE APOYO AL BENEFICIO SOCIAL"
                      width="80"
                      className="img-fluid "
                    />
                  </div>
                  <div className="text-center text-white mt-3">
                    <h4 className="rBlack">
                      ¿Cuentas con un <br /> título PABS?{" "}
                    </h4>
                    <h5 className="rLight mt-4">
                      Tu crédito ya está <br />
                      Pre-autorizado
                    </h5>

                    <div className="mt-4">
                      <a
                        href="#"
                        className="btn btn-primary btn-conoce-azul"
                        onClick={() => setCredit()}
                      >
                        <h6 className="rBlack txt-azul-marino pt-1">
                          Solicitar
                        </h6>
                      </a>
                    </div>
                    <div className="mt-4">
                      <p className="rLight mt-4">
                        ¿Qué es un Título PABS? <br />
                        <a>Click aqui</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="d-flex justify-content-center">
                    <img
                      src={icono_mujer}
                      alt="PROGRAMA DE APOYO AL BENEFICIO SOCIAL"
                      width="80"
                      className="img-fluid "
                    />
                  </div>
                  <div className="text-center text-white mt-3">
                    <h4 className="rBlack">
                      Quiero un <br /> crédito personal{" "}
                    </h4>
                    <h5 className="rLight mt-4">
                      Tu crédito en <br />
                      menos de 24 hrs
                    </h5>

                    <div className="mt-4">
                      <a
                        href="#"
                        className="btn btn-primary btn-conoce-azul"
                        onClick={() => setCredit()}
                      >
                        <h6 className="rBlack txt-azul-marino pt-1">
                          Solicitar
                        </h6>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Modal>
      <motion.div className="text-center" animate={animation}>
        <h3 className="sol1-tittle">Solicitud de préstamo</h3>
      </motion.div>
      {/* STEPS */}
      <motion.div
        className="d-none d-sm-none d-md-block p-0 m-0 "
        animate={animation2}
      >
        <div className="d-flex justify-content-center mt-4 container">
          <button className="circle-step">
            <h5 className="numbre-step">1</h5>
          </button>
          <div className="line">
            aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
          </div>
          <button className="circle-step">
            <h5 className="numbre-step">2</h5>
          </button>
          <div className="line">
            aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
          </div>
          <button className="circle-step">
            <h5 className="numbre-step">3</h5>
          </button>
        </div>
      </motion.div>
      <motion.div
        className="d-block d-sm-block d-md-none p-0 m-0 "
        animate={animation2}
      >
        <div className="d-flex justify-content-center mt-4 container">
          <button className="circle-step">
            <h5 className="numbre-step">1</h5>
          </button>
          <div className="line">aaaaaaaaaaaa</div>
          <button className="circle-step">
            <h5 className="numbre-step">2</h5>
          </button>
          <div className="line">aaaaaaaaaaaa</div>
          <button className="circle-step">
            <h5 className="numbre-step">3</h5>
          </button>
        </div>
      </motion.div>
      {/* CARDS */}
      <motion.div
        className="d-none d-sm-none d-md-block p-0 m-0 "
        animate={animation4}
      >
        <div className="d-flex justify-content-center mt-5">
          <div className="card p-4 card-redounded shadow p-2 mb-5 bg-body">
            <div className="text-center">
              <h3 className="text-strong-blue">
              Vamos a iniciar con la <span className="text-light-blue">captura de tus datos</span>{" "}
              </h3>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <button className="btn btn-shadow" onClick={sendData}>
                Continuar
              </button>
            </div>
          </div>
        </div>
      </motion.div>
      <motion.div
        className="d-block d-sm-block d-md-none p-0 m-0 "
        animate={animation4}
      >
        <div className="d-flex justify-content-center mt-5">
          <div className="card p-4 card-redounded-sm shadow p-3 mb-5 bg-body">
          <div className="text-center">
              <h3 className="text-strong-blue">
              Vamos a iniciar con la <span className="text-light-blue">captura de tus datos</span>{" "}
              </h3>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <button className="btn btn-shadow" onClick={sendData}>
                Continuar
              </button>
            </div>
          </div>
        </div>
      </motion.div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.appBar + 1 }}
        open={open}
      >
        <img
          src={logo_balnco}
          alt="PRADO'S"
          className="img-fluid p-3 img-client"
          width="300"
        />
        <CircularProgress color="primary" size={80} />
      </Backdrop>
      <Footer />
    </motion.div>
  );
}
