import React, { useEffect, useState } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { UserContext } from "../context/UserProvider";
// import { useAuth } from "../contexts/AuthContext";
import ApiClient from "../services/apiclient";

export default function PrivateRoute({ component: Component, ...rest }) {
  const history = useHistory();
  const apiClient = ApiClient.getInstance();

  const currentUser = apiClient.getUserId();
  const currentToken = apiClient.getUserToken();
  const [acceptedTermAndPrivacy, setAcceptedTermAndPrivacy] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const { userMain, updateUser } = React.useContext(UserContext);



  const getValidationTerms = () => {
    apiClient.getValidationTerms().then(resp =>{
      const {acceptedTermAndPrivacy, userDetails} = resp;
      acceptedTermAndPrivacy == false ? setAcceptedTermAndPrivacy(false) : setAcceptedTermAndPrivacy(true);
      userDetails == false ? setUserDetail(false) : setUserDetail(true);
      
    }).catch(err =>{
      console.log('error: ', err.message)
      if(err.message.includes('Entity not found: User with id')){
        localStorage.clear();
        updateUser({ ...userMain, nombre: 'Iniciar Sesión', apellidoPaterno: '' });
        history.push('/login')
      }

      if (err.message === 'Error verifying token : jwt expired') {
        localStorage.clear();
        updateUser({ ...userMain, nombre: 'Iniciar Sesión', apellidoPaterno: '' });
        history.push("/login");
      }
    })
  }


  const validateToken = () => {
    apiClient
      .getUserData()
      .then((data: any) => {
        console.log(data);
        if (data.error.status === 401) {
          localStorage.clear();
          history.push("/login");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    //validateToken();
    getValidationTerms();
  }, []);


  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          currentUser !== undefined 
          ? 
            acceptedTermAndPrivacy != null
              ?
                acceptedTermAndPrivacy == false ||  userDetail === false
                ?
                  <Redirect to="/register-form" />
                : 
                  <Component {...props} />
              : null
          : 
          <Redirect to="/login" />
        )
        /*return currentUser !== undefined ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        );*/
      }}
    ></Route>
  );
}
