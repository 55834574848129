import React, { useEffect, useState } from 'react';

export const UserContext = React.createContext();

const UserProvider = props => {
  const [userMain, setUserMain] = useState({
    token: '',
    userId: '',
    nombre: 'Iniciar Sesión',
    apellidoPaterno: '',
    apellidoMaterno: '',
    fecha_nacimiento: '',
    curp: '',
    rfc: '',
    telefono: '',
    esEmpleado: false,
    creditoNomina:'',
    percepciones_20:'',
    creditType:'personal',
    preAmount:0
  });

  const updateUser = valor => {
    console.log('called',valor)
    setUserMain(valor);
    localStorage.setItem('userLocal', JSON.stringify(valor));
  };

  useEffect(() => {
    if (localStorage.getItem('userLocal')) {
      const userLocal = JSON.parse(localStorage.getItem('userLocal'));
      setUserMain(userLocal);
    }
  }, []);

  return (
    <UserContext.Provider value={{userMain, updateUser}}>
      {props.children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {};

export default UserProvider;
