import React from 'react'
import  Footer  from '../../front/FooterI'
import  Navbar  from '../../front/NavbarI'
import logo_bco from '../../../img/logo-bco.png';
import logo_gde from '../../../img/logo-gde.png';
import productos from '../../../img/productos.png';
import logo_financiamiento from '../../../img/logo-financiamiento.png';
import logo_dire from '../../../img/logo-dire.png';
import {motion} from "framer-motion";

export default function Financiamiento() {
    return (
        <motion.div
        initial={{  opacity:0 }}
          animate={{opacity:1 }}
          exit={{opacity:0}}
          transition={{ ease: "easeInOut", duration: 0.6 }}>
            <Navbar />
            <div class="bg-financiamiento">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-4"></div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-4">
                            <div class="text-center txt-financiamiento">
                                <img src={logo_bco} alt="PERCAPITA" class="img-fluid" />
                                <div class="text-end me-5">
                                    <h6 class="rLight">FINANCIAMIENTOS</h6>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-4"></div>

                    </div>
                </div>
            </div>
            <div class="d-none d-sm-none d-md-block">
                <div class="container-fluid bg-gris">
                    <div class="row py-5">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-5">
                                <h1 class="text-center rLight txt-azul-marino txt-gracias">
                                    Gracias a
                                </h1>
                                <div class="text-end mb-4">
                                    <img src={logo_gde} alt="PERCAPITA" class="img-fluid logo-gde" />
                                    <h3 class="rMedium txt-azul h6">FINANCIAMIENTOS</h3>
                                </div>
                                <div class="txt-ayuda3 text-center">
                                    <div class="ms-5 mb-4">
                                        <h1 class="rLight txt-azul2 display-6">Te ayudamos a adquirir </h1>
                                        <h1 class="rBlack txt-azul2 display-3">tus productos</h1>
                                        <h1 class="rBlack txt-azul display-3">preferidos</h1>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-7">
                                <img src={productos} alt="PERCAPITA" class="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-block d-sm-block d-md-none">
                <div class="container-fluid bg-gris">
                    <div class="row py-5">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-5">
                                <h1 class="text-center rLight txt-azul-marino txt-gracias">
                                    Gracias a
                                </h1>
                                <div class="text-end mb-4">
                                    <img src={logo_gde} alt="PERCAPITA" class="img-fluid logo-gde" />
                                    <h3 class="rMedium txt-azul h6">FINANCIAMIENTOS</h3>
                                </div>
                                <div class="txt-ayuda3 text-center">
                                    <div class="mb-4">
                                        <h1 class="rLight txt-azul2 display-6">Te ayudamos a adquirir </h1>
                                        <h1 class="rBlack txt-azul2 display-3">tus productos</h1>
                                        <h1 class="rBlack txt-azul display-3">preferidos</h1>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-7 pt-3">
                                <img src={productos} alt="PERCAPITA" class="img-fluid pt-5" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-solarum">
                <div class="container">
                    <div class="row">
                        <div class="text-end py-5">
                            <img src={logo_financiamiento} alt="PERCAPITA FINANCIAMIENTOS" class="img-fluid" />
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-4"></div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-8">
                            <div class="text-end">
                                <div class="txt-solarum">
                                    <h4 class="rLight">Disfruta de este tequila</h4>
                                    <h1 class="rBlack">Con ojulas de oro comestible de 24K.</h1>
                                    <div class="mt-3">
                                        <a href="#" class="btn btn-primary btn-solicitar">
                                            <h6 class="pt-1 rBlack">Comenzar</h6>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="bg-dire">
                <div class="container p-0">
                    <div class="row">
                        <div class="text-end py-5">
                            <img src={logo_financiamiento} alt="PERCAPITA FINANCIAMIENTOS" class="img-fluid" />
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-4"></div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-8">
                            <div class="text-center">
                                <img src={logo_dire} alt="DIRÉ" class="img-fluid logo-dire" />

                                <div class="txt-dire">
                                    <h4 class="rLight h3">Los mejores planes de telefonía</h4>
                                    <h1 class="rBlack">con cobertura nacional e internacional</h1>
                                    <div class="mt-3">
                                        <a href="#" class="btn btn-primary btn-solicitar">
                                            <h6 class="pt-1 rBlack">Comenzar</h6>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </motion.div>
    )
}
