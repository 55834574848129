import Solicitud4 from "../components/portal/personal/Solicitud4";
import Solicitud5 from "../components/portal/personal/Solicitud5";
import Dashboard from "../components/portal/cuenta/Cuenta";
// import Dashboard2 from "../components/dashboard/dashboard.component";
// import TypeCreditApplication from "../components/pages/type-credit-application.component";
// import AccountStatus from "../components/pages/dashboard/account-status.component";
// import CreditDetail from "../components/pages/dashboard/credit-detail.component";
// import Solicitud from "../components/pages/solicitud/solicitud";
import NavPABS from "../components/portal/pabs/nav";
import Rechazado from "../components/portal/rechazado/Rechazado";
// import CreditType from "../components/pages/credit-type/credit-type";
// import ApprovedCredit from "../components/pages/dashboard/approvedCredit.component";
// import CreditProgress from "../components/pages/dashboard/credit-progress.component";
// import CreditoNoAprovado from "../components/pages/dashboard/credit-no-approved.component";
// import CreditoRechazadoAprovado from "../components/pages/dashboard/credit-rechazado-approved.component";
import PrestamoPersonalNuevo from "../components/portal/page/prestamo-personal-nuevo";
// import SinPermisoTramite from "../components/pages/dashboard/no-solicitud-credit.component";
// import ConfigCreditUser from "../components/pages/dashboard/config-credit-user.component";
// import NonCreditUser from "../components/pages/dashboard/non-credit-user.component";
// import Page4 from "../components/pages/request-form-4.component";
// import Page5 from "../components/pages/request-form-5.component";
// import Page6 from "../components/pages/request-form-6.component";
// import TermsConditionsCDC from "../components/pages/circulo-credito/term-conditions";
import { Veriff } from "../components/portal/page/veriff/veriffNew";
// import "../css/main.css";
// import "../css/ss.scss";
import { NOMINA_ACTIVE, PABS_ACTIVE, PERSONAL_ACTIVE } from "../utils/config";
import PersonalForm from "../components/portal/personal/PersonalForm";
import Validacion from "../components/portal/pabs/Validation";
import ValidacionPersonal from "../components/portal/personal/ValidacionPersonal";
import PrestamoDiremovil from "../components/portal/page/prestamo-diremovil"
import PrestamoDiremovilPromotion from "../components/portal/page/prestamo-diremovil-promotion"
const privateRoutes = [
  { path: "/solicitud-datos3", component: Solicitud4 },
  { path: "/solicitud-datos4", component: Solicitud5 },
  { path: "/dashboard", component: Dashboard },
  { path: "/veriff", component: Veriff },
  { path: "/Titulo-data", component: Validacion },
  { path: "/Personal-data", component: ValidacionPersonal },
  //{path: "/register-form",component: PersonalForm},
  //   { path: "/solicitud", component: Solicitud },
  //   { path: "/credito-nomina/Ciudad", component: NavNomina },
  { path: "/RECHAZADO/:type/:reason", component: Rechazado },
  //   { path: "/productos", component: CreditType },
  //   { path: "/credito-aprobado", component: ApprovedCredit },
  //   { path: "/proceso-credito", component: CreditProgress },
  //   // { path: "/prestamo-personal", component: PrestamoPersonalNuevo },
  //   { path: "/sin-permiso-tramite", component: SinPermisoTramite },
  //   { path: "/sin-credito", component: NonCreditUser },
  //   { path: "/autoriza-circulo-credito", component: TermsConditionsCDC },
];
//if (PERSONAL_ACTIVE)
  privateRoutes.push({
    path: "/prestamo-personal",
    component: PrestamoPersonalNuevo,
  });
  privateRoutes.push({
    path: "/prestamo-diremovil",
    component: PrestamoDiremovil,
  });
  privateRoutes.push({
    path: "/prestamo-diremovil-promotion",
    component: PrestamoDiremovilPromotion,
  });
//if (PABS_ACTIVE) 
  privateRoutes.push({ path: "/PABS", component: NavPABS });

export default privateRoutes;
