import React from "react";
import condusef from "../../img/condusef.png";
import sat from "../../img/sat.png";
import asofam from "../../img/asofom.png";
import buro_credito from "../../img/buro_credito.png";
import buro from "../../img/buro.png";
import logo_balnco from "../../img/logo_blanco.png";
import icono_ubi from "../../img/icono-ubi.png";
import icono_mail from "../../img/icono-mail.png";
import icono_phone from "../../img/icono-phone.png";
import icono_what from "../../img/icono-what.png";
import icono_fb from "../../img/icono-fb.png";
import icono_ig from "../../img/icono-ig.png";
import icono_yt from "../../img/icono-yt.png";
import materialized_blueberry from "../../img/materialized-blueberry.gif";
import Pdf from "../portal/page/pdf/catycomisiones.pdf";

export default function Footer() {
  return (
    <div>
      <div className="d-none d-sm-none d-md-block p-0 m-0 ">
        <div className="footer-clients d-flex justify-content-center p-4 container-fluid">
          <a
            className="img-client"
            href="https://www.gob.mx/condusef"
            target="_blank"
          >
            <img
              src={condusef}
              alt="PRADO'S"
              className="img-fluid p-3 img-client"
              width="130"
            />
          </a>
          <a
            className="img-client"
            href="https://www.sat.gob.mx/home"
            target="_blank"
          >
            <img
              src={sat}
              alt="PRADO'S"
              className="img-fluid p-3 img-client"
              width="95"
            />
          </a>
          <a className="img-client" href="https://asofom.mx" target="_blank">
            <img
              src={asofam}
              alt="PRADO'S"
              className="img-fluid p-3 img-client"
              width="140"
            />
          </a>
          <a
            className="img-client"
            href="https://www.burodecredito.com.mx"
            target="_blank"
          >
            <img
              src={buro_credito}
              alt="PRADO'S"
              className="img-fluid p-3 img-client"
              width="95"
            />
          </a>
          <a className="img-client" href="/buro" target="_blank">
            <img
              src={buro}
              alt="PRADO'S"
              className="img-fluid p-3 img-client"
              width="95"
            />
          </a>
        </div>
      </div>
      <div className="d-block d-sm-block d-md-none p-0 m-0 ">
        <div className="footer-clients d-flex justify-content-center p-4 container-fluid">
          <a
            className="img-client"
            href="https://www.gob.mx/condusef"
            target="_blank"
          >
            <img
              src={condusef}
              alt="PRADO'S"
              className="img-fluid p-3 img-client"
              width="130"
            />
          </a>
          <a
            className="img-client"
            href="https://www.sat.gob.mx/home"
            target="_blank"
          >
            <img
              src={sat}
              alt="PRADO'S"
              className="img-fluid p-3 img-client"
              width="95"
            />
          </a>
        </div>
        <div className="footer-clients d-flex justify-content-center p-4 container-fluid">
          <a className="img-client" href="https://asofom.mx" target="_blank">
            <img
              src={asofam}
              alt="PRADO'S"
              className="img-fluid p-3 img-client"
              width="140"
            />
          </a>
          <a
            className="img-client"
            href="https://www.burodecredito.com.mx"
            target="_blank"
          >
            <img
              src={buro_credito}
              alt="PRADO'S"
              className="img-fluid p-3 img-client"
              width="95"
            />
          </a>
        </div>
        <div className="footer-clients d-flex justify-content-center p-4 container-fluid">
          <a className="img-client" href="/buro" target="_blank">
            <img
              src={buro}
              alt="PRADO'S"
              className="img-fluid p-3 img-client"
              width="95"
            />
          </a>
        </div>
      </div>
      <div className="p-5 footer-text">
        <div className="container">
          <p>
            El Buró de Entidades Financieras contiene información de PROBENSO
            GROUP S.A. SOFOM E.N.R. sobre nuestro desempeño frente a los
            Usuarios, por la prestación de productos y servicios. Te invitamos a
            consultarlo también en la página <a href="https://www.buro.gob.mx" target="_blank">https://www.buro.gob.mx</a> ó a través
            de <a href="https://www.percapita.mx/Buro" target="_blank">https://www.percapita.mx/Buro</a>. Productos ofertados por
            Probenso Group, S.A., SOFOM, E.N.R., quién indica que, no requiere
            autorización de la Secretaría de Hacienda y Crédito Público para su
            constitución y realizar operaciones como Sociedad Financiera de
            Objeto Múltiple Entidad no Regulada y se encuentra sujeta a la
            supervisión de la Comisión Nacional Bancaria y de Valores,
            únicamente para efectos del artículo 56 de la Ley general de
            organizaciones y actividades auxiliares del crédito.
          </p>
          <p>Ejemplo representativo: monto de crédito por $3000, con tasa anual de 300% y a un plazo de 12 semanas. Pago semanal $371.54, tasa diaria .008%. Monto total a pagar: $4458 sin IVA. Sujeto aprobación de préstamo. CAT promedio para fines informativos 296.8%.  Periodo mínimo y máximo para cancelación 10 días.</p>
          <p>Es obligatorio cumplir con los siguientes requisitos: ser mayor de edad, nacionalidad mexicana, domicilio en territorio nacional y credencial para votar original vigente.</p>
          <p>Percapita únicamente realiza operaciones en México.</p>
          <p>
          Atención a usuarios de la Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros, para cualquier cuestión respecto de los servicios financieros prestados por Percapita , los datos de contacto de la CONDUSEF son los siguientes: 55 5340-0999  asimismo le informa que la página de internet de la citada Comisión es <a href="https://www.condusef.gob.mx" target="_blank">www.condusef.gob.mx</a> y que el correo electrónico al que podrá enviar sus dudas es el <a href='mailto:asesoria@condusef.gob.mx'> asesoria@condusef.gob.mx.</a>
          </p>
        </div>
      </div>

      <div className="d-none d-sm-none d-md-block">
        <footer>
          <div className="bg-footer">
            <div className="container py-5">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-1"></div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-10">
                  <div className="text-center">
                    <img
                      src={logo_balnco}
                      alt="PERCAPITA"
                      className="img-fluid"
                    />
                  </div>
                  <div className="row my-5">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                      <div className="contacto-footer">
                        <h3 className="rExtraBold txt-azul">Contacto</h3>
                        <div className="mt-3">
                          <img
                            src={icono_ubi}
                            alt="Encuentranos en: "
                            className="img-fluid icono-contacto mb-5"
                          />
                          <aside>
                            <a
                              href="https://maps.app.goo.gl/hvWmTwuNVcjdDCLi6"
                              className="a_none_style text-white"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h6 className="oRegular">
                              Calle Tomás V. Gómez 307
                              </h6> 
                              <h6 className="oRegular">
                              Ladrón de Guevara, Ladrón De Guevara
                              </h6>
                              <h6 className="oRegular">
                              44600 Guadalajara, Jal.
                              </h6>
                            </a>
                          </aside>
                          <img
                            src={icono_ubi}
                            alt="Encuentranos en: "
                            className="img-fluid icono-contacto mb-5"
                          />
                          <aside>
                            <a
                              href="https://maps.app.goo.gl/hvWmTwuNVcjdDCLi6"
                              className="a_none_style text-white"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h6 className="oRegular">
                              Ures Sur 137 5 Col.
                              </h6> 
                              <h6 className="oRegular">
                              Centro 63000.
                              </h6>
                              <h6 className="oRegular">
                              Tepic, Nayarit
                              </h6>
                            </a>
                          </aside>
                          <img
                            src={icono_mail}
                            alt="Contactanos en:"
                            className="img-fluid icono-contacto"
                          />
                          <a
                            href="mailto:contacto@percapita.mx?Subject=Solicitar%20Informes%20Percapita"
                            className="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h6 className="oRegular my-3">
                              contacto@percapita.mx
                            </h6>
                          </a>
                          <img
                            src={icono_phone}
                            alt="Contactanos al:"
                            className="img-fluid icono-contacto"
                          />
                          <a
                            href="tel:+523336168059"
                            className="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h6 className="oRegular mb-3">33 3616-8059</h6>
                          </a>
                          <a
                            href="https://api.whatsapp.com/send?phone=523315206918"
                            className="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                          <img
                            src={icono_what}
                            alt="Contactanos al:"
                            className="img-fluid icono-contacto"
                          />
                            <h6 className="oRegular"></h6>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-2">
                      <div className="menu-footer">
                        <h3 className="rExtraBold txt-azul">Menú</h3>
                        <div className="mt-3">
                          <a href="/" className="a_none_style text-white">
                            <h6 className="oRegular">Inicio</h6>
                          </a>
                          <a
                            href="https://percapita.mx/Nosotros"
                            className="a_none_style text-white"
                          >
                            <h6 className="oRegular">Nosotros</h6>
                          </a>
                          <a
                            href="https://percapita.mx/Financiamiento"
                            className="a_none_style text-white"
                          >
                            <h6 className="oRegular">Financiamiento</h6>
                          </a>
                          <a href="/" className="a_none_style text-white">
                            <h6 className="oRegular">Crédito PABS</h6>
                          </a>
                          <a
                            href="https://percapita.mx/Blog"
                            className="a_none_style text-white"
                          >
                            <h6 className="oRegular">Blog</h6>
                          </a>
                          <a
                            href="https://percapita.mx/Contacto"
                            className="a_none_style text-white"
                          >
                            <h6 className="oRegular">Contacto</h6>
                          </a>
                          <a href="/login" className="a_none_style text-white">
                            <h6 className="oRegular">Inicia Sesión</h6>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-1"></div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                      <div className="legal">
                        <h3 className="rExtraBold txt-azul">Legal</h3>
                        <div className="mt-3">
                          <a
                            href="https://percapita.mx/Terminos-y-condiciones"
                            className="a_none_style text-white"
                          >
                            <h6 className="oRegular">Términos y Condiciones</h6>
                          </a>
                          <a
                            href="https://percapita.mx/Aviso-de-privacidad"
                            className="a_none_style text-white"
                          >
                            <h6 className="oRegular">Aviso de privacidad</h6>
                          </a>
                          <a
                            //href={Pdf}
                            href="https://percapita.mx/Cat-y-comisiones"
                            target="_blank"
                            className="a_none_style text-white"
                          >
                            <h6 className="oRegular">CAT y Comisiones</h6>
                          </a>
                          <a
                            href="https://percapita.mx/Faq"
                            className="a_none_style text-white"
                          >
                            <h6 className="oRegular">Preguntas frecuentes</h6>
                          </a>
                          <a
                            href="https://percapita.mx/Derechos-arco"
                            className="a_none_style text-white"
                          >
                            <h6 className="oRegular">Derechos de Arco</h6>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-2">
                      <div className="redes">
                        <h3 className="rExtraBold txt-azul">Síguenos</h3>
                        <div className="mt-5 text-center">
                          <a
                            href="https://www.facebook.com/PercapitaPrestamos"
                            className="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={icono_fb}
                              alt="FB: PERCAPITA"
                              className="img-fluid me-3"
                            />
                          </a>
                          {/* <a href="#" className="a_none_style text-white" target="_blank" rel="noopener noreferrer">
                            <img src={icono_ig} alt="IG: PERCAPITA" className="img-fluid me-3" />
  </a>*/}
                          <a
                            href="https://www.youtube.com/channel/UCsqIIu_msdAzHDzHDdUK7Qg"
                            className="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={icono_yt}
                              alt="YT: PERCAPITA"
                              className="img-fluid"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="hr-footer" />
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                      <h5 className="oRegular txt-derechos">
                        © 2022 Percapita. Todos Los Derechos Reservados.
                      </h5>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4"></div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                      <div className="text-end">
                        <img
                          src={materialized_blueberry}
                          alt="Materialized by Blueberry"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-1"></div>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <div className="d-block d-sm-block d-md-none">
        <footer>
          <div className="bg-footer">
            <div className="container py-5">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-1"></div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-10">
                  <div className="text-center">
                    <img
                      src={logo_balnco}
                      alt="PERCAPITA"
                      className="img-fluid"
                    />
                  </div>
                  <div className="row my-5">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 mb-4">
                      <div className="contacto-footer mx-4">
                        <h3 className="rExtraBold txt-azul">Contacto</h3>
                        <div className="mt-3">
                        <aside>
                            <a
                              href="https://maps.app.goo.gl/hvWmTwuNVcjdDCLi6"
                              className="a_none_style text-white"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h6 className="oRegular">
                              Calle Tomás V. Gómez 307
                              </h6> 
                              <h6 className="oRegular">
                              Ladrón de Guevara, Ladrón De Guevara
                              </h6>
                              <h6 className="oRegular">
                              44600 Guadalajara, Jal.
                              </h6>
                            </a>
                          </aside>
                          <div className="border-top my-3"></div>
                          <aside>
                            <a
                              href="https://maps.app.goo.gl/hvWmTwuNVcjdDCLi6"
                              className="a_none_style text-white"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h6 className="oRegular">
                              Ures Sur 137 5 
                              </h6>
                              <h6 className="oRegular">
                              Col. Centro, 63000 
                              </h6>
                              <h6 className="oRegular">
                              Tepic, Nayarit.
                              </h6>
                            </a>
                          </aside>
                          <a
                            href="mailto:contacto@percapita.mx?Subject=Solicitar%20Informes%20Percapita"
                            className="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h6 className="oRegular my-3">
                              contacto@percapita.mx
                            </h6>
                          </a>
                          <a
                            href="tel:+523336168059"
                            className="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h6 className="oRegular mb-3">33 3616-8059</h6>
                          </a>
                          
                          <a
                            href="https://api.whatsapp.com/send?phone=523315206918"
                            className="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                          <img
                            src={icono_what}
                            alt="Contactanos al:"
                            className="img-fluid icono-contacto"
                          />
                            <h6 className="oRegular"></h6>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-2 mb-4">
                      <div className="menu-footer mx-4">
                        <h3 className="rExtraBold txt-azul">Menú</h3>
                        <div className="mt-3">
                          <a
                            href="http://percapita-dev.s3-website-us-west-2.amazonaws.com"
                            className="a_none_style text-white"
                          >
                            <h6 className="oRegular">Inicio</h6>
                          </a>
                          <a
                            href="https://percapita.mx/Nosotros"
                            className="a_none_style text-white"
                          >
                            <h6 className="oRegular">Nosotros</h6>
                          </a>
                          <a
                            href="https://percapita.mx/Financiamiento"
                            className="a_none_style text-white"
                          >
                            <h6 className="oRegular">Financiamiento</h6>
                          </a>
                          <a
                            href="https://app.percapita.mx/"
                            className="a_none_style text-white"
                          >
                            <h6 className="oRegular">Crédito PABS</h6>
                          </a>
                          <a
                            href="https://percapita.mx/Blog"
                            className="a_none_style text-white"
                          >
                            <h6 className="oRegular">Blog</h6>
                          </a>
                          <a
                            href="https://percapita.mx/Contacto"
                            className="a_none_style text-white"
                          >
                            <h6 className="oRegular">Contacto</h6>
                          </a>
                          <a
                            href="https://app.percapita.mx/login"
                            className="a_none_style text-white"
                          >
                            <h6 className="oRegular">Inicia Sesión</h6>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-1"></div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                      <div className="legal mx-4">
                        <h3 className="rExtraBold txt-azul">Legal</h3>
                        <div className="mt-3">
                          <a
                            href="https://percapita.mx/Terminos-y-condiciones"
                            className="a_none_style text-white"
                          >
                            <h6 className="oRegular">Términos y Condiciones</h6>
                          </a>
                          <a
                            href="https://percapita.mx/Aviso-de-privacidad"
                            className="a_none_style text-white"
                          >
                            <h6 className="oRegular">Aviso de privacidad</h6>
                          </a>
                          <a
                            //href={Pdf}
                            href="https://percapita.mx/Cat-y-comisiones"
                            target="_blank"
                            className="a_none_style text-white"
                          >
                            <h6 className="oRegular">CAT y Comisiones</h6>
                          </a>
                          {/* <a href="#" className="a_none_style text-white">
                            <h6 className="oRegular">Políticas de Cookies</h6>
                          </a> */}
                          <a
                            href="https://percapita.mx/Faq"
                            className="a_none_style text-white"
                          >
                            <h6 className="oRegular">Preguntas frequentes</h6>
                          </a>
                          <a
                            href="https://percapita.mx/Derechos-arco"
                            className="a_none_style text-white"
                          >
                            <h6 className="oRegular">Derechos Arco</h6>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-2">
                      <div className="redes mx-4">
                        <h3 className="rExtraBold txt-azul">Síguenos</h3>
                        <div className="mt-3">
                          <a
                            href="https://www.facebook.com/PercapitaPrestamos"
                            className="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={icono_fb}
                              alt="FB: PERCAPITA"
                              className="img-fluid me-3"
                            />
                          </a>
                          {/* <a href="#" className="a_none_style text-white" target="_blank" rel="noopener noreferrer">
                            <img src={icono_ig} alt="IG: PERCAPITA" className="img-fluid me-3" />
                          </a> */}
                          <a
                            href="https://www.youtube.com/channel/UCsqIIu_msdAzHDzHDdUK7Qg"
                            className="a_none_style text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={icono_yt}
                              alt="YT: PERCAPITA"
                              className="img-fluid"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="hr-footer" />
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                      <h5 className="oRegular txt-derechos text-center">
                        © 2022 Percapita. Todos Los <br /> Derechos Reservados.
                      </h5>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4"></div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                      <div className="text-center">
                        <img
                          src={materialized_blueberry}
                          alt="Materialized by Blueberry"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-1"></div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}
