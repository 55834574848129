import React from 'react'
import Navbar from '../../front/NavbarI';
import Footer from '../../front/FooterI';
import esfera_logo from '../../../img/esfera_percapita.png';
import esfera_user from '../../../img/esfera_contact.png';
import flechai  from '../../../img/flechai.png';
import flechad  from '../../../img/flechaD.png';

export default function Solicitud_11() {
    return (
        <div className="grey-background">
            <Navbar />
            <br />
            <br />
            <div className="d-block d-sm-block d-md-none p-0 m-0 mb-5">
                <div className="card card-monto">
                    <div className="container">
                        <h6 className="text-credit text-end p-2">
                            Monto solicitado:
                        </h6>
                        <h2 className="text-light-blue text-end"> $1,200</h2>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <div className="d-none d-sm-none d-md-block p-0 m-0 ">
                        <div className="card card-monto">
                            <div className="container">
                                <h6 className="text-credit text-end p-2">
                                    Monto solicitado:
                                </h6>
                                <h2 className="text-light-blue text-end"> $1,200</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="text-center">
                        <h3 className="sol1-tittle">Solicitud de préstamo</h3>
                        <p>INFORMACIÓN EXTRA</p>
                    </div>
                </div>
                <div className="col-3"></div>
            </div>
            <div className="d-flex justify-content-center">
                <img src={esfera_logo} alt="PRADO'S" className="img-fluid" width="60" />
            </div>
            <div className="text-center mt-3    ">
                <h6 className="txt-blue-strong2">DATOS LABORALES</h6>
            </div>
            {/* STEPS */}
            {/* CARDS */}
            <div className="d-flex justify-content-center mt-4 container">
                <div className="card p-4 card-redounded-lg shadow p-3 mb-5 bg-body">
                    <div className="container">
                        <form>
                            <div className="d-flex justify-content-start">
                                <img src={esfera_user} alt="PRADO'S" className="img-fluid" width="52" />
                                <p className="txt-blue-strong2 text-contact">REFERENCIA PERSONAL 01</p>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label sol1-tittle">Nombre completo</label>
                                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label sol1-tittle">Parentesco</label>
                                        <select className="form-select slect-blue text-light" aria-label="Default select example">
                                            <option selected>Selecciona una opción</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label sol1-tittle">Teléfono de contacto</label>
                                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-start">
                                <img src={esfera_user} alt="PRADO'S" className="img-fluid" width="52" />
                                <p className="txt-blue-strong2 text-contact">REFERENCIA PERSONAL 02</p>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label sol1-tittle">Nombre completo</label>
                                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label sol1-tittle">Parentesco</label>
                                        <select className="form-select slect-blue text-light" aria-label="Default select example">
                                            <option selected>Selecciona una opción</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                    <div className="mb-3">
                                        <label htmlFor="exampleInputEmail1" className="form-label sol1-tittle">Teléfono de contacto</label>
                                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                </div>
                            </div>
                            <hr className="mt-4 mb-4" />
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="d-flex justify-content-center mt-5 mb-4">
                                        <a href='/solicitud/personal/10' className="btn btn-shadow-2">
                                            <img src={flechai} alt="PRADO'S" className="img-fluid mrirht" width="10" />
                                            Regresar
                                        </a>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="d-flex justify-content-center mt-5 mb-4">
                                        <a href='/solicitud/personal/12' className="btn btn-shadow-2">
                                            Continuar
                                            <img src={flechad} alt="PRADO'S" className="img-fluid mleft" width="10" />
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
