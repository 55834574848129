import React, { useState, useEffect, useCallback } from "react";
import * as Yup from "yup";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import ApiClient from "../../../services/apiclient";
import { nextStep } from "../../../store/actions/PrestamosPersonalesActions";
import ".././../../css/style.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Footer from "../../front/FooterI";
import logo_balnco from "../../../img/logo_blanco.png";
import flechai from "../../../img/flechai.png";
import flechad from "../../../img/flechaD.png";
import { motion } from "framer-motion";
import Navbar from "../../front/NavbarI";
import { PreTramiteCreditoContext } from "../../../context/PreTramiteCreditoProvider";
import { TramiteCreditoContext } from "../../../context/TramiteCreditoProvider";
import { useHistory } from "react-router-dom";
import {validateString} from "../../../utils/StringValidator"
//import { useForm }  from "react-hook-form";
const AddressForm = ({ nominaId, nextStep }) => {
  const apiClient = ApiClient.getInstance();

  const [colonias, setColonias] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [preInfo, setPreInfo] = useState(null);
  const [amount, setAmount] = useState(0);
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const history = useHistory();
  const { preCredito, updatePreCredito, deletePreCredito } = React.useContext(
    PreTramiteCreditoContext
  );
  const { Credito, updateCredito, deleteCredito } = React.useContext(
    TramiteCreditoContext
  );
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const updateMarketing = async (id: any, data: any) => {
    await apiClient.updateMarketingTrack(id, data);
  };
  useEffect(() => {
    apiClient.getUserPreInfo().then((data) => {
      if (!data.error) {
        formik.setFieldValue("street", data.street);
        formik.setFieldValue("exteriorNumber", data.exteriorNumber);
        formik.setFieldValue("interiorNumber", data.interiorNumber);
        formik.setFieldValue("propertyType", data.propertyType);
        formik.setFieldValue("postalCode", data.postalCode);
        formik.setFieldValue("antiquity", data.antiquity);
        formik.setFieldValue("antiquityMonth", data.antiquityMonth);
        formik.setFieldValue("homePhone", data.homePhone);
        console.log(data.antiquityMonth, data.antiquity);
      }
    });
    if (localStorage.getItem("userLocal")) {
      var userLocal = JSON.parse(localStorage.getItem("userLocal"));
      setAmount(parseInt(userLocal.preAmount));
    }
    if (localStorage.getItem("preCreditoLocal")) {
      var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
      if (
        credito.stepMarketing != "address-form-init" &&
        credito.typeLoanMarketing === "Personal"
      )
        var data = {
          processName: "address-form-init",
        };
      updatePreCredito({
        ...preCredito,
        stepMarketing: "address-form-init",
      });
      updateMarketing(credito.idProcessMarketing, data);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      postalCode: "",
      street: "",
      exteriorNumber: "",
      interiorNumber: "",
      delegation: "",
      state: "",
      city: "",
      suburb: "",
      propertyType: "",
      antiquity: 0,
      antiquityMonth: 0,
      homePhone: "",
    },
    validationSchema: Yup.object({
      postalCode: Yup.string()
        .matches(/(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/)
        .required(),
      street: Yup.string().required(),
      exteriorNumber: Yup.string().required(),
      state: Yup.string().required(),
      city: Yup.string().required(),
      delegation: Yup.string().required(),
      suburb: Yup.string().required(),
      propertyType: Yup.string().required(),
      antiquity: Yup.number().required(),
      antiquityMonth: Yup.number().required(),
      homePhone: Yup.string().matches(/[0-9]+/),
    }),
    onSubmit: (values) => {
      setIsLoading(true);

      apiClient
        .addressFormDiremovilData(values, nominaId)
        .then((data) => {
          apiClient.sendSmsCdc();
          if (localStorage.getItem("preCreditoLocal")) {
            var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
            if (
              credito.idProcessMarketing !== "" &&
              credito.typeLoanMarketing === "Personal"
            ) {
              var dd = {
                processName: "address-form-finish",
              };
              updatePreCredito({
                ...preCredito,
                stepMarketing: "address-form-finish",
              });
              updateMarketing(credito.idProcessMarketing, dd);
            }
          }
          nextStep("CDC");
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
          if (e.message === "city ​​outside the coverage area") {
            updateCredito({
              estatusSolicitud: "solicitud-no-aprobada",
            });
            history.push("/RECHAZADO/personal/DENIED");
          }
        });
    },
  });

  useEffect(() => {
    const setPostalCode = () => {
      if (formik.values.postalCode.trim().length == 5) {
        const postalCode = formik.values.postalCode.trim();

        apiClient
          .getAdrressFromZipCode(postalCode)
          .then((data) => {
            formik.setFieldValue("state", data.estado);
            formik.setFieldValue("city", data.ciudad || data.municipio);
            formik.setFieldValue("delegation", data.municipio);
            formik.setFieldValue("postalCode", formik.values.postalCode);
            setColonias(data?.colonias);
          })
          .catch((e) => console.log(e));
      }
    };

    if (formik.values.postalCode !== "") {
      setPostalCode();
    }
  }, [formik.values.postalCode]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.6 }}
      className="grey-background"
    >
      <Navbar />
      <br />
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-block d-sm-block d-md-none p-0 m-0 mb-5"
      >
        <div className="card card-monto">
          <div className="container">
            <h6 className="text-credit text-end p-2">Monto solicitado:</h6>
            <h2 className="text-light-blue text-end">
              {" "}
              {amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h2>
          </div>
        </div>
      </motion.div>
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="row"
      >
        <div className="col-3">
          <div className="d-none d-sm-none d-md-block p-0 m-0 ">
            <div className="card card-monto">
              <div className="container">
                <h6 className="text-credit text-end p-2">Monto solicitado:</h6>
                <h2 className="text-light-blue text-end">
                  {" "}
                  {amount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="text-center">
            <h3 className="sol1-tittle">Solicitud de préstamo</h3>
            <p>Por favor, rellena los siguientes campos con tu información</p>
          </div>
        </div>
        <div className="col-3"></div>
      </motion.div>
      {/* STEPS */}
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-none d-sm-none d-md-block p-0 m-0 "
      >
        <div className="d-flex justify-content-center mt-4 container">
          <button className="circle-step-blue">
            <h5 className="numbre-step">1</h5>
          </button>
          <div className="line">
            aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
          </div>
          <button className="circle-step-blue">
            <h5 className="numbre-step">2</h5>
          </button>
          <div className="line">
            aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
          </div>
          <button className="circle-step-blue">
            <h5 className="numbre-step">3</h5>
          </button>
        </div>
      </motion.div>
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-block d-sm-block d-md-none p-0 m-0 "
      >
        <div className="d-flex justify-content-center mt-4 container">
          <button className="circle-step-blue">
            <h5 className="numbre-step">1</h5>
          </button>
          <div className="line">aaaaaaaaaaaa</div>
          <button className="circle-step-blue">
            <h5 className="numbre-step">2</h5>
          </button>
          <div className="line">aaaaaaaaaaaa</div>
          <button className="circle-step-blue">
            <h5 className="numbre-step">3</h5>
          </button>
        </div>
      </motion.div>
      {/* CARDS */}
      <motion.div
        initial={{ y: "50%", opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, scale: 1 }}
        exit={{ y: "50%", transition: { duration: 0.8 } }}
        transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
        className="d-flex justify-content-center mt-4 container"
      >
        <div className="card p-4 card-redounded-lg shadow p-3 mb-5 bg-body">
          <div className="container">
            <p className="txt-blue-strong">
              Rellena los campos vacíos con los datos de tu domicilio
            </p>
            <form id="div-perfil" onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <label htmlFor="street" className="form-label sol1-tittle">
                  Calle *
                </label>
                <input
                  type="input"
                  id="street"
                  aria-describedby="emailHelp"
                  onChange={(e) => {
                    const value = e.target.value || "";
                    formik.setFieldValue(
                      "street",
                      validateString(value.toUpperCase())
                    );
                  }}
                  value={formik.values.street}
                  onBlur={formik.handleBlur}
                  className={
                    formik.errors.street
                      ? " form-control is-invalid"
                      : "form-control"
                  }
                />
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="exteriorNumber"
                      className="form-label sol1-tittle"
                    >
                      Numero exterior *
                    </label>
                    <input
                      type="input"
                      id="exteriorNumber"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        formik.setFieldValue(
                          "exteriorNumber",
                          validateString(value.toUpperCase())
                        );
                      }}
                      value={formik.values.exteriorNumber}
                      onBlur={formik.handleBlur}
                      className={
                        formik.errors.exteriorNumber
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="interiorNumber"
                      className="form-label sol1-tittle"
                    >
                      Número interior
                    </label>
                    <input
                      type="input"
                      id="interiorNumber"
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        formik.setFieldValue(
                          "interiorNumber",
                          validateString(value.toUpperCase())
                        );
                      }}
                      value={formik.values.interiorNumber}
                      onBlur={formik.handleBlur}
                      className={
                        formik.errors.interiorNumber
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="postalCode"
                      className="form-label sol1-tittle"
                    >
                      Código postal *
                    </label>
                    <input
                      type="input"
                      id="postalCode"
                      aria-describedby="emailHelp"
                      onChange={formik.handleChange}
                      value={formik.values.postalCode}
                      onBlur={formik.handleBlur}
                      className={
                        formik.errors.postalCode
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="suburb" className="form-label sol1-tittle">
                      Colonia *
                    </label>
                    <select
                      id="suburb"
                      aria-describedby="emailHelp"
                      onChange={formik.handleChange}
                      value={formik.values.suburb}
                      onBlur={formik.handleBlur}
                      className={
                        errors.nationality
                          ? " form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                    >
                      <option value="">Seleccione una opción</option>
                      {colonias.map((data) => {
                        return (
                          <option key={data?.colonia} value={data?.colonia}>
                            {data?.colonia}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="state" className="form-label sol1-tittle">
                      Estado *
                    </label>
                    <input
                      type="input"
                      id="state"
                      aria-describedby="emailHelp"
                      onChange={formik.handleChange}
                      value={formik.values.state}
                      onBlur={formik.handleBlur}
                      className={
                        formik.errors.state
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="city" className="form-label sol1-tittle">
                      Ciudad *
                    </label>
                    <input
                      type="input"
                      id="city"
                      aria-describedby="emailHelp"
                      onChange={formik.handleChange}
                      value={formik.values.city}
                      onBlur={formik.handleBlur}
                      className={
                        formik.errors.city
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="antiquity"
                      className="form-label sol1-tittle"
                    >
                      Antigüedad en el domicilio
                    </label>
                    <select
                      id="antiquity"
                      name="antiquity"
                      aria-describedby="emailHelp"
                      onChange={formik.handleChange}
                      value={formik.values.antiquity}
                      onBlur={formik.handleBlur}
                      className={
                        formik.errors.antiquity
                          ? "form-select slect-blue text-light is-invalid"
                          : "form-select slect-blue text-light"
                      }
                    >
                      <option value="">Seleccione una opción</option>
                      <option value="0">Menos de 6 meses</option>
                      <option value="1">1 año</option>
                      <option value="2">2 años</option>
                      <option value="3">3 años</option>
                      <option value="4">4 años</option>
                      <option value="5">5 años</option>
                      <option value="6">
                        Mas de 6 años
                      </option>
                    </select>
                  </div>
                  {/* <div className="mb-3">
                    <label
                      htmlFor="antiquityMonth"
                      className="form-label sol1-tittle"
                    >
                      Antigüedad en el domicilio
                    </label>
                    <input
                      type="number"
                      placeholder="Meses"
                      id="antiquityMonth"
                      aria-describedby="emailHelp"
                      onChange={formik.handleChange}
                      value={formik.values.antiquityMonth}
                      onBlur={formik.handleBlur}
                      className={
                        formik.errors.antiquityMonth
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                    />
                  </div> */}
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="mb-3">
                    <label
                      htmlFor="delegation"
                      className="form-label sol1-tittle"
                    >
                      Delegación *
                    </label>
                    <input
                      type="input"
                      id="delegation"
                      aria-describedby="emailHelp"
                      onChange={formik.handleChange}
                      value={formik.values.delegation}
                      onBlur={formik.handleBlur}
                      className={
                        formik.errors.delegation
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="propertyType"
                      className="form-label sol1-tittle"
                    >
                      Tipo de propiedad *
                    </label>
                    <select
                      tabIndex={15}
                      className="form-select slect-blue text-light"
                      id="propertyType"
                      name="propertyType"
                      onChange={formik.handleChange}
                      value={formik.values.propertyType}
                    >
                      <option value="FAMILIAR">Familiar</option>
                      <option value="PROPIA">Propia</option>
                      <option value="RENTADA">Rentada</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="homePhone"
                      className="form-label sol1-tittle"
                    >
                      Teléfono fijo
                    </label>
                    <input
                      type="input"
                      id="homePhone"
                      aria-describedby="emailHelp"
                      onChange={formik.handleChange}
                      value={formik.values.homePhone}
                      onBlur={formik.handleBlur}
                      className={
                        formik.errors.homePhone
                          ? " form-control is-invalid"
                          : "form-control"
                      }
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6"></div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6"></div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="d-flex justify-content-center mt-5 mb-4">
                    <a href="/prestamo-personal" className="btn btn-shadow-2">
                      <img
                        src={flechai}
                        alt="PRADO'S"
                        className="img-fluid mrirht"
                        width="10"
                      />
                      Regresar
                    </a>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="d-flex justify-content-center mt-5 mb-4">
                    <button type="submit" className="btn btn-shadow-2">
                      Continuar
                      <img
                        src={flechad}
                        alt="PRADO'S"
                        className="img-fluid mleft"
                        width="10"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </motion.div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.appBar + 1 }}
        open={isLoading}
      >
        <img
          src={logo_balnco}
          alt="PRADO'S"
          className="img-fluid p-3 img-client"
          width="300"
        />
        <CircularProgress color="primary" size={80} />
      </Backdrop>
      <Footer />
    </motion.div>
  );
};

const mapStateToProps = ({ personalCreditReducer }) => ({
  user: personalCreditReducer?.user ?? {},
  nominaId: personalCreditReducer?.nominaId ?? "",
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      nextStep,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressForm);
