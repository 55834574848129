import React, { useEffect, useState } from "react";
import AlertMessage from "../../../alert.component";
import ApiClient from "../../../../services/apiclient";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import esfera_user from "../../../../img/esfera_contact.png";
import flechai from "../../../../img/flechai.png";
import flechad from "../../../../img/flechaD.png";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import logo_balnco from "../../../../img/logo_blanco.png";
import { motion } from "framer-motion";
import { PreTramiteCreditoContext } from "../../../../context/PreTramiteCreditoProvider";
import {validateString} from "../../../../utils/StringValidator"
import LoanType from "../../../loan-type.component";

export default function Referencias({
  userData,
  setUserData1,
  nextStep,
  userData1,
  setCurrentPage,
  setUserData,
  productName,
  prevStep,
}) {
  const history = useHistory();
  const [openMessage, setOpenMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const apiClient = ApiClient.getInstance();
  const [isLoading, setIsLoading] = useState(false);
  const { preCredito, updatePreCredito, deletePreCredito } = React.useContext(
    PreTramiteCreditoContext
  );
  localStorage.setItem("loanType", "Titulo");

  const onsubmitForm = (values: any, actions: any) => {
    userData1.personalReferences = values;

    apiClient
      .pabsContinue(userData1)
      .then((data) => {
        nextStep();
      })
      .catch((err) => {
        setOpenMessage(true);
        setMessage(
          "No se puede continuar al siguiente paso, verifique la informacion."
        );
        setSeverity("error");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    console.log("UserData primera parte", userData);
    /*apiClient.setProductName(productName).then(data => {
      console.log(data);
    }).catch(err => console.error(err));*/
  }, []);

  useEffect(() => {
    const checkUserVeriffValidated = async () => {
      const isValidatedRes = await apiClient.checkUserVeriffValidated();
      console.log(isValidatedRes);
      if (isValidatedRes.veriffValidated !== true) {
        history.push("/veriff?from=PABS");
        return;
      }
    };
    const timeout = setTimeout(() => {
      checkUserVeriffValidated();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const validationSchema = Yup.object().shape({
    personalReferences: Yup.array().of(
      Yup.object({
        name: Yup.string().required("Requerido"),
        relationship: Yup.string().required("Requerido"),
        contactNumber: Yup.string().required("Requerido"),
      })
    ),
  });

  const handleClose = () => {
    setOpenMessage(false);
  };
  const {
    values,
    errors,
    touched,
    isSubmitting,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      personalReferences: [
        {
          name: "",
          relationship: "",
          contactNumber: "",
        },
        {
          name: "",
          relationship: "",
          contactNumber: "",
        },
      ],
    },
    validationSchema: Yup.object().shape({
      personalReferences: Yup.array().of(
        Yup.object({
          name: Yup.string().required("Requerido"),
          relationship: Yup.string().required("Requerido"),
          contactNumber: Yup.string().required("Requerido"),
        })
      ),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      let data = [
        {
          name: values.personalReferences[0].name,
          relationship: values.personalReferences[0].relationship,
          contactNumber: values.personalReferences[0].contactNumber.toString(),
        },
        {
          name: values.personalReferences[1].name,
          relationship: values.personalReferences[1].relationship,
          contactNumber: values.personalReferences[1].contactNumber.toString(),
        },
      ];
      userData1.personalReferences = data;

      apiClient
        .pabsContinue(userData1)
        .then((data) => {
          if (localStorage.getItem("preCreditoLocal")) {
            var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
            if (
              credito.idProcessMarketing !== "" &&
              credito.typeLoanMarketing === "Titulo"
            ) {
              var dd = {
                processName: "personal-references-finished",
              };
              updatePreCredito({
                ...preCredito,
                stepMarketing: "personal-references-finished",
              });
              updateMarketing(credito.idProcessMarketing, dd);
            }
          }
          nextStep();
        })
        .catch((err) => {
          setOpenMessage(true);
          setMessage(
            "No se puede continuar al siguiente paso, verifique la informacion."
          );
          setSeverity("error");
          setIsLoading(false);
        });
    },
  });
  const updateMarketing = async (id: any, data: any) => {
    const activeCredit = await apiClient.getActiveCredit();
    const credit = await apiClient.getCredit(activeCredit.creditType);
    data.pabsLoanId = credit.id;
    data.userId = credit.userId;
    data.amount = credit.amount;
    await apiClient.updateMarketingTrack(id, data);
  };

  useEffect(() => {
    if (localStorage.getItem("preCreditoLocal")) {
      var credito = JSON.parse(localStorage.getItem("preCreditoLocal"));
      if (
        credito.stepMarketing != "personal-references-init" &&
        credito.typeLoanMarketing === "Titulo"
      )
        var data = {
          processName: "personal-references-init",
        };
      updatePreCredito({
        ...preCredito,
        stepMarketing: "personal-references-init",
      });
      updateMarketing(credito.idProcessMarketing, data);
    }
  }, []);
  useEffect(()=> {
    const preinfo = async()=> {
      await apiClient.getUserPreInfo().then((data) => {
        if (!data.error) {
            setFieldValue("personalReferences[0].name", data.nameRef1);
            setFieldValue("personalReferences[0].relationship", data.relationShip1);
            setFieldValue("personalReferences[0].contactNumber", data.phoneRef1);
            setFieldValue("personalReferences[1].name", data.nameRef2);
            setFieldValue("personalReferences[1].relationship", data.relationShip2);
            setFieldValue("personalReferences[1].contactNumber", data.phoneRef2);
        }
      });
    }
    preinfo();
  }, [])
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.6 }}
    >
      <br />
      <LoanType
        loanType={localStorage.getItem("loanType")}
        userValidated={true}
      />
      <AlertMessage
        open={openMessage}
        message={message}
        handleClose={handleClose}
        severity={severity}
      />
      <div className="d-flex justify-content-center mt-4 container">
        <div className="card p-4 card-redounded-lg shadow p-3 mb-5 bg-body">
          <div className="container">
            <form onSubmit={handleSubmit}>
              <motion.div
                initial={{ y: "50%", opacity: 0, scale: 0.5 }}
                animate={{ y: 0, opacity: 1, scale: 1 }}
                exit={{ y: "50%", transition: { duration: 0.8 } }}
                transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
                className="d-flex justify-content-start"
              >
                <img
                  src={esfera_user}
                  alt="PRADO'S"
                  className="img-fluid"
                  width="52"
                />
                <p className="txt-blue-strong2 text-contact">
                  REFERENCIA PERSONAL 01 
                </p>
              </motion.div>
              <br />
              <motion.div
                initial={{ y: "50%", opacity: 0, scale: 0.5 }}
                animate={{ y: 0, opacity: 1, scale: 1 }}
                exit={{ y: "50%", transition: { duration: 0.8 } }}
                transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
                className="row"
              >
                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label sol1-tittle"
                    >
                      Nombre completo
                    </label>
                    <input
                      type="input"
                      className="form-control"
                      id="personalReferences[0].name"
                      value={values.personalReferences[0].name}
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setFieldValue(
                          "personalReferences[0].name",
                          validateString(value.toUpperCase())
                        );
                      }}
                      onBlur={handleBlur}
                    />
                    {errors.personalReferences && (
                      <div className="invalid-feedback">
                        El campo tipo propiedad es requerido
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <div className="mb-3">
                    <label
                      htmlFor="personalReferences[0].relationship"
                      className="form-label sol1-tittle"
                    >
                      Parentesco
                    </label>
                    <select
                      className="form-select slect-blue text-light"
                      aria-label="Default select example"
                      id="personalReferences[0].relationship"
                      value={values.personalReferences[0].relationship}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option selected>Selecciona una opción</option>
                      <option value="Familiar">Familiar</option>
                      <option value="Amistad">Amistad</option>
                    </select>
                    {errors.personalReferences && (
                      <div className="invalid-feedback">
                        El campo tipo propiedad es requerido
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label sol1-tittle"
                    >
                      Teléfono de contacto
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="emailHelp"
                      id="personalReferences[0].contactNumber"
                      value={values.personalReferences[0].contactNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </motion.div>
              <motion.div
                initial={{ y: "50%", opacity: 0, scale: 0.5 }}
                animate={{ y: 0, opacity: 1, scale: 1 }}
                exit={{ y: "50%", transition: { duration: 0.8 } }}
                transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
                className="d-flex justify-content-start"
              >
                <img
                  src={esfera_user}
                  alt="PRADO'S"
                  className="img-fluid"
                  width="52"
                />
                <p className="txt-blue-strong2 text-contact">
                  REFERENCIA PERSONAL 02
                </p>
              </motion.div>
              <br />
              <motion.div
                initial={{ y: "50%", opacity: 0, scale: 0.5 }}
                animate={{ y: 0, opacity: 1, scale: 1 }}
                exit={{ y: "50%", transition: { duration: 0.8 } }}
                transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
                className="row"
              >
                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label sol1-tittle"
                    >
                      Nombre completo
                    </label>
                    <input
                      type="input"
                      className="form-control"
                      id="personalReferences[1].name"
                      value={values.personalReferences[1].name}
                      aria-describedby="emailHelp"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setFieldValue(
                          "personalReferences[1].name",
                          validateString(value.toUpperCase())
                        );
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <div className="mb-3">
                    <label
                      htmlFor="personalReferences[0].relationship"
                      className="form-label sol1-tittle"
                    >
                      Parentesco
                    </label>
                    <select
                      className="form-select slect-blue text-light"
                      aria-label="Default select example"
                      id="personalReferences[1].relationship"
                      value={values.personalReferences[1].relationship}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option selected>Selecciona una opción</option>
                      <option value="Familiar">Familiar</option>
                      <option value="Amistad">Amistad</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label sol1-tittle"
                    >
                      Teléfono de contacto
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="emailHelp"
                      id="personalReferences[1].contactNumber"
                      value={values.personalReferences[1].contactNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </motion.div>
              <hr className="mt-4 mb-4" />
              <motion.div
                initial={{ y: "50%", opacity: 0, scale: 0.5 }}
                animate={{ y: 0, opacity: 1, scale: 1 }}
                exit={{ y: "50%", transition: { duration: 0.8 } }}
                transition={{ ease: "easeInOut", duration: 0.8, delay: 0.4 }}
                className="row"
              >
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  {/* <div className="d-flex justify-content-center mt-5 mb-4">
                    <a
                      href="/solicitud/personal/10"
                      className="btn btn-shadow-2"
                    >
                      <img
                        src={flechai}
                        alt="PRADO'S"
                        className="img-fluid mrirht"
                        width="10"
                      />
                      Regresar
                    </a>
                  </div> */}
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="d-flex justify-content-center mt-5 mb-4">
                    <button disabled={!isValid} type="submit" className="btn btn-shadow-2">
                      Continuar
                      <img
                        src={flechad}
                        alt="PRADO'S"
                        className="img-fluid mleft"
                        width="10"
                      />
                    </button>
                  </div>
                </div>
              </motion.div>
            </form>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.appBar + 1 }}
        open={isLoading}
      >
        <img
          src={logo_balnco}
          alt="PRADO'S"
          className="img-fluid p-3 img-client"
          width="300"
        />
        <CircularProgress color="primary" size={80} />
      </Backdrop>
    </motion.div>
  );
}
