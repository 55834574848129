
export enum AnnuaLRate {
  TasaA = 0.70,
  TasaB = 1.10,
  TasaC = 1.50,
  TasaD = 3.0,
  TasaE = 2.7,
  TasaTest = 3.6
}

export enum AnnuaLRateEmployee {
  TasaA = 0.12,
  TasaB = 0.18,
  TasaC = 0.36
}

export enum Iva {
  decimal = 0.16
}

export interface CreateAmortizationInterface {
  amount: number; //Monto del prestamo
  paymentTerm: number; //Numero de pagos a realizar
  recurrencePayments: number; // Semanal(3), Quincenal(2)
  customerRating: number; // Tipo de usuario (A == 1,B == 2,C == 3)
}
